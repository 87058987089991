import axios from "axios";

import { bidBaseUrl } from "utils/configurable";


const briefFolder = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/folder-add`, data, { headers })
        return response
    } catch (error) {
        return error

    }
}


const briefPermissionList = async (data) => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/briefcash-permission-list`, data,{ headers })
        return response
    } catch (error) {
        return error

    }
}

const briefFolderList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/listgenral-doctroal`, { headers })
        return response
    } catch (error) {
        return error

    }
}


const FolderSpace = async()=>{
    const headers = {
        "authorization": localStorage.getItem('bidToken'),
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/get-storage-details`,{headers})
        return response
    } catch (error) {
        return error
    }
}

const briefDocumentList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/doctrol-files-list`, { headers })
        return response
    } catch (error) {
        return error

    }
}

const briefDocAdd = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/genral-doctroal-add`, data, { headers })
        return response
    } catch (error) {
        return error

    }
}

const briefDocList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/listgenral-doctroal`, data, { headers })
        return response
    } catch (error) {
        return error

    }
}

const briefFileDelete = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.delete(`${bidBaseUrl}/doctrol-files-delete`, { data: data, headers: headers })
        return response
    } catch (error) {
        return error

    }
}

const briefFolderDelete = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.delete(`${bidBaseUrl}/folder-delete`, { data: data, headers: headers })
        return response
    } catch (error) {
        return error

    }
}

const briefDocType = async (data) => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/ist-type`, data, { headers })
        return response
    } catch (error) {
        return error

    }
}


export const briefcase = {
    briefFolder,
    briefFolderList,
    briefDocAdd,
    briefDocList,
    briefDocType,
    briefDocumentList,
    briefFileDelete,
    briefFolderDelete,
    FolderSpace,
    briefPermissionList
}