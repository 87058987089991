// @ts-nocheck
import React, { useEffect } from "react";
import '../../../assests/tenderStatCommonCss/Erroor404/error.css'
import { useNavigate } from "react-router-dom";
import GgLogo from '../../../assests/img/logo gg.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "../auth/Login.css"
import "../../../assests/tenderStatCommonCss/Erroor404/error.css"

import { useSelector } from 'react-redux';
import ROUTES from "Constants/Routes";

function Error404() {
  const navigate = useNavigate()
  const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
  const location = window.location.pathname
  useEffect(() => {
    if (location === '/error') {
      console.log("error")
    } else {
      navigate(ROUTES.ERROR)
    }

  }, [location]);
  return (
    <>
      <div className='error_mainCont'>
        <div className="loginLogo"><img src={GgLogo} alt="" onClick={() => dynamicMenu?.filter(val => val?.fld_id === '24')?.map(item =>
          navigate(`/${item?.action_url}`))} width={100} /></div>
        <h1 className='code'>404</h1>
        <h2 className='desc'>oops... There's something wrong.</h2>

        <div className="d-flex arrow_error">
          <button className="arrow_btn" onClick={() => navigate(ROUTES?.BD_LOGIN)}><ArrowBackIcon /></button>
          <button className="mainButton ghostButton fs-5" 
          // onClick={() => dynamicMenu?.filter(val => val?.fld_id === '24')?.map(item =>
          //   navigate(`/${item?.action_url}`)
          // )}
          onClick={() => navigate(ROUTES?.BD_LOGIN)}
          >Go To Home</button>
        </div>
      </div>
    </>
  );
}

export default Error404;
