// @ts-nocheck
import React, { useState } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import numberToWords from 'number-to-words';
import { fontGrid } from '@mui/material/styles/cssUtils';
import RupeeImg from "../../../assests/img/rupee.png"

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    rowItem: {
        flexDirection: 'row',
        borderBottom: 1,
        borderBottomColor: "#efefef",
        paddingBottom: 7,
        marginBottom: 7
    },
    rowView: {
        flexDirection: "row"
    },
    totalchargeable: {

        // borderWidth: 1,
        borderColor: "black",
        padding: 10,
        marginBottom: 7,
        borderTop: 1,
        height: 80

    },
    label: {
        width: 300,
        fontSize: 11,
    },
    labelPDF: {
        fontSize: 11,
        paddingTop: 5,
        paddingBottom: 5,
        width: "30%",
    },
    text: {
        paddingRight: 15,
        textAlign: "right",
        fontSize: 11,
        marginRight: 20,

    },
    textPDF: {
        alignSelf: 'flex-start',

        width: "65%",
        fontSize: 11,
        paddingTop: 5,
        paddingBottom: 5,
    },
    maintable: {
        paddingTop: 5,
        paddingBottom: 10,
        borderBottom: 1,
        borderBottomColor: '#444444'
    },
    logo: {
        width: 10,
        // marginTop: 20
    },

});
export function convertToWords(number) {
    const ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
    const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

    function convertLessThanThousand(num) {
        let result = '';

        const hundreds = Math.floor(num / 100);
        const tensAndOnes = num % 100;

        if (hundreds > 0) {
            result += ones[hundreds] + ' Hundred';
            if (tensAndOnes > 0) {
                result += ' and ';
            }
        }

        if (tensAndOnes === 10) {
            result += 'Ten';
        } else if (tensAndOnes >= 10 && tensAndOnes <= 19) {
            result += teens[tensAndOnes - 10];
        } else if (tensAndOnes > 0) {
            result += tens[Math.floor(tensAndOnes / 10)];
            if (tensAndOnes % 10 > 0) {
                result += ' ' + ones[tensAndOnes % 10];
            }
        }

        // let result = '';

        // const hundreds = Math.floor(num / 100);
        // const tensAndOnes = num % 100;

        // if (hundreds > 0) {
        //     result += ones[hundreds] + ' Hundred ';
        // }

        // if (tensAndOnes >= 10 && tensAndOnes <= 19) {
        //     result += teens[tensAndOnes - 10];
        // } else {
        //     result += tens[Math.floor(tensAndOnes / 10)];
        //     if (tensAndOnes % 10 > 0) {
        //         result += ' ' + ones[tensAndOnes % 10];
        //     }
        // }

        return result.trim();
    }

    function convertCroresLakhsThousands(num) {
        const crores = Math.floor(num / 10000000);
        const remainderAfterCrores = num % 10000000;
        const lakhs = Math.floor(remainderAfterCrores / 100000);
        const remainderAfterLakhs = remainderAfterCrores % 100000;
        const thousands = Math.floor(remainderAfterLakhs / 1000);
        const remainder = remainderAfterLakhs % 1000;

        let result = '';

        if (crores > 0) {
            result += convertLessThanThousand(crores) + ' Crore';
            if (crores > 1) {
                result += 's';
            }
        }

        if (lakhs > 0) {
            result += ' ' + convertLessThanThousand(lakhs) + ' Lakh';
            if (lakhs > 1) {
                result += 's';
            }
        }

        if (thousands > 0) {
            if (result !== '') {
                result += ' ';
            }
            result += convertLessThanThousand(thousands) + ' Thousand';
        }

        if (remainder > 0) {
            result += ' ' + convertLessThanThousand(remainder);
        }

        return result.trim();
        // const crores = Math.floor(num / 10000000);
        // const remainderAfterCrores = num % 10000000;
        // const lakhs = Math.floor(remainderAfterCrores / 100000);
        // const remainderAfterLakhs = remainderAfterCrores % 100000;
        // const thousands = Math.floor(remainderAfterLakhs / 1000);
        // const remainder = remainderAfterLakhs % 1000;
        // let result = '';

        // if (crores > 0) {
        //     result += convertLessThanThousand(crores) + ' Crore';
        //     if (crores > 1) {
        //         result += 's';
        //     }
        // }

        // if (lakhs > 0) {
        //     result += ' ' + convertLessThanThousand(lakhs) + ' Lakh';
        //     if (lakhs > 1) {
        //         result += 's';
        //     }
        // }

        // if (thousands > 0) {
        //     result += ' ' + convertLessThanThousand(thousands) + ' Thousand';
        // }

        // if (remainder > 0) {
        //     result += ' ' + convertLessThanThousand(remainder);
        // }

        // return result.trim();
    }

    function convertDecimal(decimalPart) {
        if (decimalPart !== 0) {
            decimalPart = Math.round(decimalPart * 100); // considering up to 2 decimal places
            return convertLessThanThousand(decimalPart) + ' Paisa';
        }

    }

    const integerPart = Math.floor(number);
    const decimalPart = number - integerPart;



    let result = convertCroresLakhsThousands(integerPart);

    if (integerPart === 1 && decimalPart > 0) {
        result += ' ' + ' Rupee' + ' and ' + convertDecimal(decimalPart);

    } else {
        if (decimalPart !== 0) {
            result += ' ' + ' Rupees' + ' and ' + convertDecimal(decimalPart);
        }
        else {
            result += ' ' + ' Rupees';
        }


    }
    // if (decimalPart > 0) {
    //     result +=  ' and ' + convertDecimal(decimalPart);
    // }

    return result.trim();
}

const InvoiceTableFooter = ({ items, transactionId }) => {
    console.log(items, "llll")
    const [numericValue] = useState(((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2));





    return (
        <>
            <View style={styles.maintable}>
                <View style={{ flexDirection: "row" }}>
                    <View style={{ width: "40%" }}></View>
                    <View>
                        {/* {items.map(item => <View style={styles.rowItem}>
                            <Text style={[styles.label]}>Taxable Amount : </Text>
                            <Text style={styles.text}>{item?.amount}</Text>
                        </View>)} */}


                        <View style={styles.rowItem}>
                            <Text style={[styles.label, { color: "#000" }]}>CGST 9% :- </Text>
                            <Text style={[styles.text, { color: "#000" }]}>{(parseFloat(items[0].amount) * 0.09).toFixed(2)}</Text>
                        </View>

                        <View style={styles.rowItem}>
                            <Text style={[styles.label, { color: "#000" }]}>SGST 9% :- </Text>
                            <Text style={[styles.text, { color: "#000" }]}>{(parseFloat(items[0].amount) * 0.09).toFixed(2)}</Text>
                        </View>

                        {/* <View style={styles.rowItem}>
                            <Text style={[styles.label, { color: "#666666" }]}>Round Of : </Text>
                            <Text style={[styles.text, { color: "#666666" }]}>{(parseFloat(items[0].amount) * 0.09).toFixed(2)}</Text>
                        </View> */}
                        <View style={[styles.rowItem]}>
                            <Text style={[styles.label]}>Total Invoice Amount:- </Text>
                            <View style={[styles.rowView]}><Image style={styles.logo} src={RupeeImg} />
                                <Text style={[styles.text]}> {((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2)}</Text>
                            </View>

                        </View>


                    </View>
                </View>
                <View style={styles.totalchargeable} >
                    <Text style={[styles.labelPDF]}>Amount Chargeable (in words)</Text>
                    <Text style={[styles.textPDF, { width: '100%' }]}>{convertToWords(((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2))} Only</Text>
                </View>


            </View>


        </>
    )
};

export default InvoiceTableFooter