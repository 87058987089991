// @ts-nocheck
import React, { useState, useEffect } from 'react';
import AttachFileOutlinedIcons from '@mui/icons-material/AttachFileOutlined';

import { Input, Form, Row, Col, Upload, Drawer, Select, Card, Button, Space, Mentions, Avatar } from 'antd';
import skipBack from '../../assets/images/skip-back.png'
import _ from 'lodash';
import 'quill/dist/quill.snow.css'
import { SendOutlined, CloseOutlined, UploadOutlined } from '@ant-design/icons';
import file from '../../assets/images/icons/file_present.png'
import pic from '../../assets/images/profile.jpg'
// import { BackgroundColor } from '@icon-park/react';
import ReactQuill from 'react-quill'
import { toast } from 'react-toastify';


import downloadFileIcon from "../../assets/images/download.png"
import dayjs from 'dayjs';
import { DashboardServiceApi } from 'Services/bidgrid/dashboard/DashboardAPi';
import { Link, useNavigate } from 'react-router-dom';
import ROUTES from 'Constants/Routes';

const { TextArea } = Input;
const { Option } = Mentions;

const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        label: i.toString(36) + i,
        value: i.toString(36) + i,
    });
}

const initialState = {
    files: [],
    client_id: null,
    country_id: null,
    state_id: null,
    tender_value: '',
    sector_id: null,
    funding_id: null,
    lead_comp_ids: null,
    jv_ids: null,
    associative_ids: null
}

const AddRequestDrawer = (props) => {
    const { documentBlobReq, addRequestDrawer, setAddRequestDrawer, onClose, tenderRequestDetail, requestsopen, employeeListVal } = props
    const [commentValue, setCommentValue] = useState('');
    // const [comments, setComments] = useState([]);
    const [selectedOption, setSelectedOption] = useState(initialState);
    const [statelist, setStateList] = useState([])
    const [showReplyBox, setShowReplyBox] = useState(true);

    const [getReplyData, setGetReplyData] = useState([])
    // const [disableBtn, setDisableBtn] = useState(false)

    const [editor, setEditor] = useState('')
    const [isCleared, setIsCleared] = useState(true);

    // upload button

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [showNotification, setShowNotification] = useState(false);

    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);
    const navigate = useNavigate()
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    const handleSelectChange = (name, value) => {
        setSelectedOption({ ...selectedOption, [name]: value });
    }

    const handleSubmit = async () => {

    }

    const handleResetFun = () => {
        setSelectedOption('')
        setEditor('')
        props?.onClose()
    }

    const handleKeyDown = (event) => {
        if (isCleared && event.key === ' ') {
            event.preventDefault();
        }
    };

    const handleEditorChange = (content, delta, source, editor) => {
        setEditor(content.trimStart());
        setIsCleared(content.trim()?.replace(/<[^>]*>/g, '') === '');
    };


    const handleFileChange = ({ fileList: newFileList }) => setFileList(newFileList);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <UploadOutlined />
        </button>
    );
    const handleCommentChange = (value) => {
        setCommentValue(value);
    };

    const handleReply = () => {
        // setShowReplyBox(false)
    };
    const handleOpenReplyBox = () => {
        // setShowReplyBox(!showReplyBox);
    }
    const handleDownloadPdf = () => {
        const docAuth = 'https://web.growthgrids.com/'
        const apiUrl = `${docAuth}${tenderRequestDetail?.reequest_data?.file_path}/${tenderRequestDetail?.reequest_data?.file_name}`;
        documentBlobReq(tenderRequestDetail?.reequest_data?.file_name, apiUrl)
    }

    const handleReplyPdf = (filePath, filename) => {
        const docAuth = 'https://web.growthgrids.com/'
        const apiUrl = `${docAuth}${filePath}/${filename}`;
        documentBlobReq(filename, apiUrl)
    }



    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        );
    };


    useEffect(() => {
        if (tenderRequestDetail?.reequest_data?.id) {
            getReply()
        }

    }, [tenderRequestDetail])


    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "link", "image", "align", "size",
    ];

    // reply list
    const getReply = async () => {
        const formData = new URLSearchParams();
        formData.append('tender_request_id', tenderRequestDetail?.reequest_data?.id)

        let result = await DashboardServiceApi.fetchReplyList(formData);
        if (result?.data?.status === '1') {
            setGetReplyData(result?.data?.data)
        } else {
            setGetReplyData([])
        }

    }


    // add reply
    const addRequest = async () => {
        setShowNotification(true);
        if (editor !== '') {
            const formData = new FormData();
            formData.append('tender_request_id', tenderRequestDetail?.reequest_data?.id)
            formData.append('reply_tndr_req', editor)
            fileList?.forEach((file, index) => {
                formData.append('file', file.originFileObj);
            });
            let result = await DashboardServiceApi.addReplyApi(formData);
            if (result?.data?.status === '1') {
                setFileList([])
                setEditor('')
                await getReply()
            }
        } else {
            notify('Message is required')
        }
        setTimeout(() => {
            setShowNotification(false);
        }, 2500);
    }

    useEffect(() => {
        if (tenderRequestDetail?.reequest_data?.id) {
            getReply()
        }

    }, [tenderRequestDetail])

    const handleRequestNavigate = (val) => {
        let str = ROUTES.BD_TENDERDETAILS.replace(':id', val)
        navigate(str, { state: { type: 'tender_request' } });
    }

    return (
        <Drawer className='bd_drawer_main'
            closeIcon={<img src={skipBack} alt='' />}
            title={props?.countVal === true ? "Add  Tender" : 'Generate Request:'}
            placement="right" onClose={() => { onClose(); setStateList([]); setFileList([]); setEditor('') }}
            open={addRequestDrawer || requestsopen} width={800}>

            <div className='bd_req_drw_main'>
                <div className='bd_rq_drawer'>
                    <div className="textDet">Subject - <span>{tenderRequestDetail?.reequest_data?.subject} </span></div>
                    <div className="textDet">Date - <span>{tenderRequestDetail?.reequest_data?.created_at !== null ? dayjs(tenderRequestDetail?.reequest_data?.created_at).format('YYYY-MM-DD') : '-'}</span></div>
                </div>

                <div className='desc_req_drawer'>
                    <div className='tenderTitle' onClick={() => handleRequestNavigate(tenderRequestDetail?.reequest_data?.tender_id)}>{tenderRequestDetail?.tender_Data?.tender_name}</div>
                    <div className='desc_pera_drwer'>{tenderRequestDetail?.reequest_data?.desc_details?.replace(/<[^>]*>/g, '')}</div>
                    {/* <div className='desc_pera_drwer'>Comment Text -{tenderRequestDetail?.reequest_data?.comment_txt}</div> */}

                    <div onClick={handleDownloadPdf} className="downloadFile">
                        <span><img src={downloadFileIcon} /></span>
                        Download File
                    </div>

                    {/* <ul className='bd_req_list'>
                        <li>Construction and upgradation to 2 lane with paved shoulder of</li>
                        <li>Balance Work form design km 52.700(prem nagar) to km 66.453 (new thathri) of 14.84km length</li>
                        <li>Balance Work form design km 52.700(prem nagar) to km 66.453 (new thathri) of 14.84km length</li>

                    </ul> */}

                    <div className='show_meet_links'>
                        <a to="https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fmeetup-join%2F19%3"></a>
                    </div>
                </div >

                <div>
                    <p>Reply</p>
                </div>


                {
                    getReplyData?.length > 0 ? getReplyData?.map((item, index) => {
                        return (
                            <>
                                <div className='bd_req_rply_list'>
                                    <div className='bd_req_img_section'>
                                        {/* <img className='bd_workd_profile_img_main' alt='' src={pic} width={20} height={20} /> */}
                                        <Avatar
                                            style={{ backgroundColor: '#f56a00' }}
                                            size="large"
                                        >
                                            {employeeListVal?.find(val => val?.id === item?.reply_from_userid)?.userfullname?.charAt(0)}
                                        </Avatar>
                                    </div>
                                    <div className='bd_work_emp_details'>
                                        <div className='docu_upload_icons_desc'>
                                            <p className='bd_workd_profile_desc_dd'>{employeeListVal?.find(val => val?.id === item?.reply_from_userid)?.userfullname}</p>
                                            <p className='req_Date'>{dayjs(item?.created_at).format('YYYY-MM-DD')}</p>
                                        </div>

                                        <div className='docu_desc'>
                                            <p className='bd_workd_profile_desc_dd'>{item?.reply_tndr_req?.replace(/<[^>]*>/g, '')}</p>
                                            {
                                                item?.file_name !== null ?
                                                    <div onClick={() => handleReplyPdf(item?.file_path, item?.file_name)} className="downloadFile"><img src={file} />1</div>
                                                    :
                                                    <></>
                                            }
                                        </div>



                                    </div>

                                </div>
                            </>
                        )
                    })

                        :
                        <p>No Data Found</p>

                }

                {/* <div className='bd_req_rply_list'>
                    <div className='bd_req_img_section'>
                        <img className='bd_workd_profile_img_main' alt='' src={pic} width={20} height={20} />
                    </div>
                    <div className='bd_work_emp_details'>
                        <div className='docu_upload_icons_desc'>
                            <p className='bd_workd_profile_desc_dd'>Vijay Kumar Jangid</p>
                            <p className='req_Date'>21/12/2023</p>
                        </div>
                        <div className='docu_desc'>
                            <p className='bd_workd_profile_desc_dd'>Dashboard UI design is a visually engaging and intuitively structured interface tailored to streamline project management</p>
                        </div>
                    </div>

                </div> */}

                <div className='reply_input_comment' >
                    <div className='reply_box'>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="write your content ...."
                            name='desc_details'
                            onKeyDown={handleKeyDown}
                            onChange={handleEditorChange}
                            value={editor}
                            style={{ height: "230px" }}
                        >
                        </ReactQuill>
                        <div className='reply_icons'>
                            <Upload
                                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                maxCount={1}
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleFileChange}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <button className="BG_mainButton" onClick={addRequest} disabled={showNotification}>Reply <SendOutlined /></button>
                        </div>

                    </div>
                </div>
            </div >

        </Drawer >
    )

}
export default AddRequestDrawer