// @ts-nocheck
import { bidProspective } from "Services/bidgrid/prospective/bidProspective";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {  bidCountryAction } from "Redux/actions/common/DropdownAction";
import { AddTenderList } from "Services/bidgrid/add/AddTender";

const BidGridMaster = () => {
    const { BidCountry } = useSelector((state) => state.bidDropdownCalVal)
    const { bidgridData } = useSelector((state) => state.loginData)
    const objDetiail = new FormData()
    const dispatch = useDispatch()
    const getBidCountry = async () => {
        try {
            const res = await bidProspective.getCountryList()
            if (res?.data?.status === '1') {
                dispatch(bidCountryAction(res?.data?.data))
            }
        } catch {
            dispatch(bidCountryAction([]))

        }
    }
useEffect(() => {
    getBidCountry()
},[])
}

export default BidGridMaster



