// @ts-nocheck
import { Helmet } from 'react-helmet';
// import { requestForToken } from './firebase';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import DropdownValues from './Includes/DropdownValues';
import { useEffect } from 'react';
import Error404 from 'common/pages/Error404/Error404';
import { ToastContainer } from 'react-toastify';
import BdLogin from './BidGrid/pages/login/Login'
import BidGridApp from 'BidGrid/BidGridApp';


function App() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = currentYear - (currentDate.getMonth() < 3 ? 1 : 0);
  const endYear = startYear + 1;
  const financialYear = `${startYear}-${endYear}`;
  localStorage.setItem('fin_year', financialYear)


  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>Doc Grid</title>
      </Helmet>
      <ToastContainer position="bottom-center" autoClose={1500} />
      <Router>
        <DropdownValues />
        <Routes>
        <Route path="/" element={<Navigate to="/document/" replace />} />
          <Route path="/document/" element={<Suspense fallback={<div></div>}><BdLogin /></Suspense>} />
          <Route path="/document/*" element={<Suspense fallback={<div></div>}><BidGridApp /></Suspense>} />
           <Route path='/error' element={<Error404 />} /> 
           <Route path="*" element={<Navigate to="/error" replace />} /> 
        </Routes>
      </Router>
    </>
  );
}


export default App;
