// @ts-nocheck
import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Signature from './Signature';
import Invoicehsn from './Invoicehsn';



const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 15,
  },
});

const InvoiceItemsTable = ({ invoice }) => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <View style={{ borderWidth: 1 }}>
        <InvoiceTableRow items={invoice.items} transactionId={invoice?.trxnId} />
        <InvoiceTableBlankSpace />
        <InvoiceTableBlankSpace />
        <InvoiceTableFooter items={invoice.items} />
      </View>
      <View style={{ marginTop: 40 }}>
        <Invoicehsn items={invoice?.items} />
      </View>


      <Signature item={invoice?.items} />

    </View>
  )
};

export default InvoiceItemsTable