// @ts-nocheck
import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = "#90e5fc"
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#efefef',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
        color: 'white',
        marginBottom:2
    },
    description: {
        width: '60%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    amount: {
        width: '15%',
    },

});

const InvoiceTableBlankSpace = ({ rowsCount }) => {
   
    return (
        <View style={styles.row}>
            <Text style={styles.description}></Text>
            <Text style={styles.qty}></Text>
            <Text style={styles.rate}></Text>
            <Text style={styles.amount}></Text>
        </View>
    )

};

export default InvoiceTableBlankSpace