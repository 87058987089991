import axios from "axios";
import { bidBaseUrl } from "utils/configurable";

const updateUserDetail = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.put(`${bidBaseUrl}/user-details-update`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const changePassword = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/user-change-password`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const oldPassword = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/check-user-old-password`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const updateUserProfile = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/user-profile-update`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};

const updateUserProfilePic = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/user-profile-pic-update`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};



export const userDetailsApi = {
    updateUserDetail,
    changePassword,
    oldPassword,
    updateUserProfile,
    updateUserProfilePic
}