// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { Calendar } from 'antd';
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from 'react-redux';
import indiaIMg from '../../assets/images/icons/india.png';
import uaeImg from '../../assets/images/icons/uae.png';
import ukImg from '../../assets/images/icons/uk.png';
import { briefcase } from '../../../Services/briefcase/briefcase';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const Dashboard = () => {
 // redux containers of following user
 const { userBidInfo } = useSelector((state) => state.userDetails)
 // hooks initialization
 // store day message
 const [message, setMessage] = useState('') 
 const [Folderlist, setFolderList] = useState()
 const [FolderSpace, setFolderSpace] = useState()

 // timezone states
 const [timeData, setTimeData] = useState({
  india: '',
  uk: '',
  dubai: ''
 });


 

 // folder list
 const FolderFileList = async () => {
  try {
   const response = await briefcase.briefFolderList()
   if (response?.data?.status === "1") {
    setFolderList(response?.data)
   }
  } catch (error) {

  }
 }


  // folder space
  const FolderFileSpace = async () => {
    try {
     const response = await briefcase.FolderSpace()
     if (response?.data?.status === "1") {
      setFolderSpace(response?.data)
     }
    } catch (error) {
  
    }
   }


 // calling each apis of dashboard which will render first
 useEffect(() => {
  FolderFileList() 
  FolderFileSpace()
 }, [])

 // dashboard message effects
 useEffect(() => {
  const currentHour = new Date().getHours();
  const morningStart = 6; // 6:00 AM
  const noonStart = 12; // 12:00 PM
  const eveningStart = 18; // 6:00 PM
  // Check the current time against the defined time segments
  if (currentHour >= morningStart && currentHour < noonStart) {
   setMessage('Good Morning')
  } else if (currentHour >= noonStart && currentHour < eveningStart) {
   setMessage('Good Afternoon')
  } else {
   setMessage('Good Evening')
  }
 }, [])

 // timezone updates
 useEffect(() => {
  const fetchTime = () => {
   const indiaDate = new Date().toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
    // second: '2-digit',
    hour12: true
   });
   const ukDate = new Date().toLocaleString('en-GB', {
    timeZone: 'Europe/London', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
    // second: '2-digit',
    hour12: true
   });
   const dubaiDate = new Date().toLocaleString('en-US', {
    timeZone: 'Asia/Dubai', day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
    // second: '2-digit',
    hour12: true
   });
   const parts = dubaiDate?.split('/');
   const [timePart, amPmPart] = parts[2].split(',');
   const rearrangedUsaDate = `${parts[1]}/${parts[0]}/${timePart.toLowerCase()},${amPmPart.toLowerCase()}`;
   setTimeData({
    india: indiaDate,
    uk: ukDate,
    dubai: rearrangedUsaDate
   });
  };
  fetchTime();
  const intervalId = setInterval(fetchTime, 1000);
  return () => clearInterval(intervalId);
 }, []);


 return (
  <>
   {/* <Breadcrumb data={str} /> */}
   <div className='bd_main' style={{ marginTop: 78 }}>
    {/* card section */}
    <div className='bd_herosection'>
     <div className='container-fluid'>
      <div className='bd_heading'>
       <h2>{message} {userBidInfo?.userfullname}</h2>
       <div className='time_dashboard'>
        <span><img src={indiaIMg} alt='img' />{timeData?.india}</span>
        <span><img src={uaeImg} alt='img' />{timeData?.dubai}</span>
        <span><img src={ukImg} alt='img' />{timeData?.uk}</span>
       </div>
      </div>
      <div className='bd_card mt-5'>
       <div className='row'>
        <div className='col-xl-3'>
         <div className="bd_cards bg1">
          <Link to={'/document/briefcase'}>
          <div className="d-flex">
           <span className='bd_cards_heading'>Total Directory</span>
           <h1>{Folderlist?.totalFoldersCount} Folders</h1>
          </div>
          </Link>
          <div className='bd_cards_content'>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="128" height="106" viewBox="0 0 128 106" fill="none">
           <rect x="36.9" y="-38.9" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="-0.9" y="0.9" width="84.6" height="84.6" transform="matrix(-1 0 0 1 131.4 -27.2)" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="58.5" y="-13.7001" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
          </svg>
         </div>
        </div>

        <div className='col-xl-3'>
         <div className="bd_cards bg1">
         <Link to={'/document/briefcase'}>
          <div className="d-flex">
           <span className='bd_cards_heading'>Total Files</span>
           <h1><h1>{Folderlist?.totalFilesCount} Files</h1></h1>
          </div>
          </Link>
          <div className='bd_cards_content'>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="128" height="106" viewBox="0 0 128 106" fill="none">
           <rect x="36.9" y="-38.9" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="-0.9" y="0.9" width="84.6" height="84.6" transform="matrix(-1 0 0 1 131.4 -27.2)" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="58.5" y="-13.7001" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
          </svg>
         </div>
        </div>

        <div className='col-xl-3'>
         <div className="bd_cards bg1">
        
          <div className="d-flex">
           <span className='bd_cards_heading'>Total Consume Space</span>
           <h1>{FolderSpace?.data}</h1>
          </div>
         
          <div className='bd_cards_content'>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="128" height="106" viewBox="0 0 128 106" fill="none">
           <rect x="36.9" y="-38.9" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="-0.9" y="0.9" width="84.6" height="84.6" transform="matrix(-1 0 0 1 131.4 -27.2)" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="58.5" y="-13.7001" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
          </svg>
         </div>
        </div>

        <div className='col-xl-3'>
         <div className="bd_cards bg1">
          <div className="d-flex">
           <span className='bd_cards_heading'>Last Log-in</span>
            <h2>{dayjs(userBidInfo?.last_login).format('YYYY-MM-DD h:mm A')}</h2>
          </div>
          <div className='bd_cards_content'>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="128" height="106" viewBox="0 0 128 106" fill="none">
           <rect x="36.9" y="-38.9" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="-0.9" y="0.9" width="84.6" height="84.6" transform="matrix(-1 0 0 1 131.4 -27.2)" stroke="#F2F2F2" strokeWidth="1.8" />
           <rect x="58.5" y="-13.7001" width="84.6" height="84.6" stroke="#F2F2F2" strokeWidth="1.8" />
          </svg>
         </div>
        </div>

       </div>
      </div>
     </div>
    </div>
    {/* calendar section */}
    <div className='bd_dashboard_calendar'>
     <div className='container-fluid'>
      <Calendar fullscreen={true} />
     </div>
    </div>
   </div>
  </>
 )

}

export default Dashboard


