// @ts-nocheck
import axios from 'axios';
import { bidBaseUrl } from 'utils/configurable';

const getWidgetByRole = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/get-widgetsperm_onrole`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const teamTaskList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/dashboard-teamtask`, null, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const teamTaskDetail = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/dashboard-teamtask-details`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}


const getRequestList = async () => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/get-requests-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const requestsDetail = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/get-requests-details`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const calendarByDate = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/calender-data-bydate`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const calendarByMonth = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/calender-data-byMonth`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const deptCalendarByDate = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/dept-calender-data-byDate`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const deptCalendarByMonth = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/dept-calender-data-byMonth`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const fetchToBeSubmittedTenders = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.get(`${bidBaseUrl}/to-be-submitted`, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}


const fetchSubmittedTenders = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.get(`${bidBaseUrl}/submitted-tenders-dashboard`, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const fetchReplyList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/reply-tndr-req-list`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const addReplyApi = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/reply-tndr-req-add`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}




const fetchAwaitingTenders = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.get(`${bidBaseUrl}/await-tenders-dashboard`, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}
const fetchWonTenders = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.get(`${bidBaseUrl}/won-tenders-dashboard`, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const fetchNotifications = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.get(`${bidBaseUrl}/notification-list`, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}

const notificationSeen = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/notification-seen`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}
const getDeisgnationOfUser = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        let result = await axios.post(`${bidBaseUrl}/designation-edit`, data, { headers });
        return result;
    }

    catch (error_msg) {
        return error_msg;
    }
}
export const DashboardServiceApi = {
    getWidgetByRole, teamTaskList, teamTaskDetail, getRequestList, requestsDetail, calendarByDate, calendarByMonth,
    fetchSubmittedTenders, fetchToBeSubmittedTenders, fetchAwaitingTenders, fetchWonTenders, fetchReplyList, addReplyApi,
    fetchNotifications, notificationSeen, getDeisgnationOfUser, deptCalendarByDate, deptCalendarByMonth
}