// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useDispatch } from 'react-redux';
import ROUTES from '../../../Constants/Routes';
const Breadcrumb = ({ data }) => {
  const [isActive, setIsActive] = useState(false);
  const [bread, setBread] = useState('')
  const navigate = useNavigate()
  const handleNavigate = () => {

    navigate(ROUTES.DASHBOARD)
  }





  useEffect(() => {
    const parts = data.split('/');
    const decodedParts = parts.map(part => decodeURIComponent(part.trim()));
    const reconstructedString = decodedParts.join('/');
    setBread(reconstructedString)
  }, [data])

  return (
    <>
      <ul className={isActive ? "breadcrumb remove-static-cls " : "breadcrumb"}>
        <li onClick={handleNavigate}><HomeOutlinedIcon /></li>
        <ArrowForwardIosOutlinedIcon className='arrow_icons' />
        <li onClick={handleNavigate} className='active'>{bread}</li>
      </ul>
    </>
  );
};

export default Breadcrumb;
