const ROUTES = {

    // Common Routes
    BD_LOGIN: "/document/login",
    ROLEPERMISSIONS: '/document/role-permissions/:id',
    DASHBOARD: "/document/dashboard",
    MODELS: "/document/model",
    LOGO: "/document/master/Logo",
    COUNTRYLIST: "/document/master/countrylist",
    STATELIST: "/document/master/statelist",
    CITYLIST: "/document/master/citylist",
    EMPLOYEE: "/document/master/employee",
    EMPLOYEE_STATUS: "/document/master/employee_status",
    BD_PROFILE: "/document/profile",
    Adduser:'/document/add-user',
    BD_EMP_PROFILE: "/document/profile/:id",
    document_share: "/document/document",
    EMPLOYEELIST: '/document/pages/employeelist',
}

export default ROUTES