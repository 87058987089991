export const STRINGS = {
    LOGIN_SUCCESS_MESSAGE: "Login sucessfull!",
    DEFAULT_COLLECTION_VALUE: "DEFAULT_CONSTANT_ALL",
    OUR_PRODUCT: 'Our Products',
    SIGNUP_FAILED_MESSAGE: "Please enter a correct valid number.",
    Tender_value: "estm_value",
    FREE_TRAIL_SUBMIT_SUCCESS : "We will get back to you shortly!",
    SIGNUP_SUCCESS : "Thank you for choosing Tender Grids!"
}

export const COMMON_STRINGS = {
    REGISTER_EMAIL : "Please enter your registered Email",
    ALREADY_EMAIL:"Email already exists.",
    CORRECT_EMAIL : "Please enter a valid Email address.",
    EMPTY_ALL_FIELDS : "Please fill out all the required fields.",
    EMPTY_EMAIL :"Please enter your Email.",
    EMPTY_NAME:"Please enter your Full Name.",
    EMPTY_PASSWORD :'Please enter the Password.',
    INVALID_USER_PASSWORD : "Invalid Username / Invalid Password.",
    TRY_LATER : 'Please try again later!',
    INVALID_CREDENTIAL  : "Invalid credentials.",
    TRY_AFTER_MIN : ' Oops! Try again after a few minutes.',
    IF_SIMILAR_PASSWORD : " Password should not be the same as the first name, last name, email and mobile number.",
    REQUIRED_LOWER_LETTER_PASSWORD : "Password should contain a lowercase letter.",
    REQUIRED_UPPER_LETTER_PASSWORD :"Password should contain an uppercase letter.",
    REQUIRED_NUMBER_PASSWORD :"Password must include numeric characters.",
    REQUIRED_SPECIAL_PASSWORD : "Password must include special characters.",
    REQUIRED_LENGTH_PASSWORD : "Password length should be min 8 and max 16 characters.",
    REQUIRED_STRONG_PASSWORD:" Password is strong.",
    REQUIRED_NUMBER : "Enter your 10 digit mobile number.",
    REQUIRED_10_DIGIT_NUMBER :"Please enter a valid 10-digit Mobile Number.",
    INVALID_NUMBER:'  Invalid Number.',
    INVALID_DATE : "Invalid Date.",
    NO_AVAILABLE : "Rows not available.",
    REQUIRED_MOBILE_NUMBER:"Mobile Number is required.",
    VALID_EMAIL : 'Please enter a valid Email Address.',
    ENTER_MOBILE_NUMBER : " Please enter your Mobile Number.",
    SELECT_AUTHORITIES:'Please select  authorities.',
    SELECT_STATE:'Please select states.',
    NAME_MANDATORY :'Entering first name is mandatory.',
    PROFILE_UPDATE_SUCCESSFULLY : "User profile successfully updated.",
    ENTER_OTP :'Please enter OTP.',
    OTP_VERIFY : "Please enter the OTP to verify your account.",
    CORRECT_OTP:'Please enter accurate OTP!',
    WRONG_FILE:"Incorrect file format.",
    FILL_QUERY : "Please fill out your query!",
    REQUIRED_KEYWORD : "Entering  keyword is mandatory!",
    REQUIRED_MESSAGE:"Entering message is mandatory.",
    REQUIRED_SUBJECT:"Entering subject is mandatory.",
    ENTER_PASSWORD_CONFIRMPASSWORD :'Enter a new password and confirm the password.',
    ENTER_CONFIRMPASSWORD : 'Please enter the confirmed password.',
    ENTER_NEW_PASSWORD : 'Please enter the new password.',
    ENTER_OLD_PASSWORD : 'Please enter the old  password.',
    OLD_NEW_PSW_NOT_SAME:"New password shouldn't be the same as the previous one.",
    PASSWORD_MISMATCH :' Password did not match.',
    PASSWORD_CHANGE_SUCCESS:"Password changed successfully,please login again.",
    USER_ADD_SUCCESS:" User successfully added.",
    LOGIN_FIRST : 'Please login first.',
}




