// @ts-nocheck
import React, { useEffect, useState, useMemo } from 'react';
import { Button, Input, Drawer, Form, Row, Col  } from 'antd';
import {  AddUser } from '@icon-park/react';
import DataTable from "BidGrid/components/dataTable/DataTable";
import SkipBack from '../../BidGrid/assets/images/skip-back.png'
import dayjs from 'dayjs';

import { bidEmployeeList } from 'Services/bidgrid/employeeList/bidEmployeeList';
import { toast } from 'react-toastify';

import Breadcrumb from 'BidGrid/components/BidBreadCrumb/Breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';
import { COMMON_STRINGS } from 'Constants/Strings';
import ROUTES from 'Constants/Routes';
const showActions = true;

const initialstate = {
    firstname: '',
    lastname: '',
    company_id: null,
    email: '',
    contactnumber: '',
    password: '',
    country_id: null,
    state_id: null,
    city_id: null,
    date_of_birth: null,
    full_address_1: '',
    zip_code: '',
    profileimg: ''

}
const EmployeeList = () => {
    const [openDrawer, setOpenDrawer] = useState(false)
    const [addEmployee, setAddEmployee] = useState(initialstate)
    const [employeeListVal, setEmployeeListVal] = useState([])
    const [flattenedProjects, setFlattenedProjects] = useState([]);
    const [propData, setPropData] = useState({})
    const [Permission,setPermission] = useState()
    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const location = useLocation();
    const val = location?.pathname;
    const str = val.replace('/', '')
    const navigate = useNavigate()
    const getUserList = async () => {
        try {
            const response = await bidEmployeeList.getUserList()
            if (response?.data?.status === "1") {
                setEmployeeListVal(response?.data?.data)
                // setDropdownData(prevState => ({ ...prevState, reporting_manager: response?.data?.data }))
            }
        } catch (error) {

        }
    }
    const handleSubmit = async (value) => {
        const formData = new URLSearchParams();
        formData.append('firstname', value?.firstname);
        formData.append('lastname', value?.lastname);
        formData.append('role_id', 0);
        formData.append('email', value?.email);
        formData.append('contactnumber', value?.contactnumber);
        formData.append('password', value?.password);
        formData.append('company_id', 0);
        formData.append('department_id', 0);
        formData.append('designation_id', 0);
        formData.append('jobgrade_id', 0);
        formData.append('reporting_mngr_id', 0);
        formData.append('unit_id', 0);
        try {
            await form.validateFields();
            const response = await bidEmployeeList.addEmployee(formData)
            if (response?.data?.status === "1") {
                notifySuccess(response?.data?.message);
                form.resetFields()
                setAddEmployee(initialstate)
                setOpenDrawer(false)
                await getUserList()
            } else {
                notify(response?.response?.data?.message);
            }

        } catch (error) {
            console.log(error, "api error")
        }
    }

    const employeeTableData = useMemo(() => {
        return flattenedProjects;
    }, [flattenedProjects]);

    const columnLabels = {
        full_name: { name: 'Full Name', required: true, width: 200 },
        email: { name: 'Email', required: true, width: 300 },
        contact: { name: 'Contact Number', required: true, width: 200 },
    };

    useEffect(() => {
        const flattenedData = employeeListVal?.map(project => ({
            key: project.id,
            row_id: project?.id,
            full_name: project?.userfullname,
            created_by: project?.createdby_username?.userfullname,
            email: project?.email,
            contact: project?.contactnumber,
            ...project
        }));

        setFlattenedProjects(flattenedData);
    }, [employeeListVal]);


    const getPropData = (val) => {
        setPropData(val)
        navigate(ROUTES.BD_EMP_PROFILE.replace(':id', val?.id))
    }
    const getPermission = (val)=>{
        setPermission(val)
        navigate(ROUTES.ROLEPERMISSIONS.replace(':id', val?.id))
    }
    const predefinedValues = () => {
        let newObj = {
            firstname: propData?.firstname || '',
            lastname: propData?.lastname || '',
            company_id: propData?.company_id || '',
            email: propData?.email || '',
            contactnumber: propData?.contactnumber || '',
            password: propData?.password || '',
            country_id: propData?.country_id || '',
            state_id: propData?.state_id || '',
            city_id: propData?.city_id || '',
            date_of_birth: propData?.date_of_birth !== null ? dayjs(propData?.date_of_birth) : null,
            full_address_1: propData?.full_address_1 || '',
            zip_code: propData?.zip_code || '',
            profileimg: propData?.profileimg || ''
        }
        setAddEmployee((prevState) => {
            return {
                ...prevState,
                ...newObj
            };
        });
        form.setFieldsValue(newObj);



    }
    const handleReset = () => {
        form.resetFields()
        setAddEmployee(initialstate)
        // setErrorMessage("");
    }
    useEffect(() => {
        if (propData?.id) {
            predefinedValues()
        }
    }, [propData]);

    useEffect(() => {
        getUserList()
    }, []);
    const validateStrongPassword = (_, value) => {
        const lowercaseRegex = /[a-z]/;
        const uppercaseRegex = /[A-Z]/;
        const numericRegex = /[0-9]/;
        const specialCharacterRegex = /[@$!%*?&]/;
        const minLength = 8;
        const maxLength = 16;

        if (!value) {
            return Promise.reject('');;
        }

        if (value.length > maxLength) {
            return Promise.reject(`Password must not exceed ${maxLength} characters`);
        }

        if (!lowercaseRegex.test(value)) {
            return Promise.reject(COMMON_STRINGS.REQUIRED_LOWER_LETTER_PASSWORD);
        }

        if (!uppercaseRegex.test(value)) {
            return Promise.reject(COMMON_STRINGS.REQUIRED_UPPER_LETTER_PASSWORD);
        }

        if (!numericRegex.test(value)) {
            return Promise.reject(COMMON_STRINGS.REQUIRED_NUMBER_PASSWORD);
        }

        if (!specialCharacterRegex.test(value)) {
            return Promise.reject(COMMON_STRINGS.REQUIRED_SPECIAL_PASSWORD);
        }

        if (value.length < minLength) {
            return Promise.reject(COMMON_STRINGS.REQUIRED_LENGTH_PASSWORD);
        }

        return Promise.resolve();
    };
    const validateContactNumber = (_, value) => {
        // Regular expression to match exactly 10 digits
        const regex = /^\d{10}$/;

        if (!value || regex.test(value)) {
            return Promise.resolve();
        }

        return Promise.reject('Contact number must be a 10-digit number');
    };
    const handleEmployeeChange = (name, e) => {
        const trimmedValue = e.target.value.trimStart(); 
        form.setFieldsValue({ [name]: trimmedValue }); 
        form2.setFieldsValue({ [name]: trimmedValue }); 
    };
    return (
        <>
            <Breadcrumb data={str} />
            <div className="bd_emplist_main">
                <div className="bd_empHeader">
                    <div className="bd_emplist_main_header">Employee List</div>
                    <button className="BG_mainButton" onClick={() => { setOpenDrawer(true) }}>
                        <AddUser theme="outline" size="22" fill="#fff" strokeWidth={3} strokeLinecap="butt" /> Add Employee
                    </button>
                </div>
                <DataTable
                    columnLabels={columnLabels}
                    dataSource={employeeTableData}
                    showActions={showActions}
                    setOpen={setOpenDrawer}
                    getPropData={getPropData}
                    getPermission={getPermission}
                />
            </div>
            <Drawer closeIcon={<img src={SkipBack} alt='' />} title='Add Employee' placement="right" onClose={() => { setOpenDrawer(false); handleReset(); }} open={openDrawer} width={800}>
                <Form
                    form={form}
                    name="control-hooks"
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row gutter={20}>
                        <Col sm={12} >
                            <Form.Item label="First Name" name='firstname' rules={[{ required: true, message: 'First name is required' }]} >
                                <Input placeholder="Enter Here" onChange={(e) => handleEmployeeChange('firstname', e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12}>
                            <Form.Item label="Last Name" name='lastname' rules={[{ required: true, message: 'Last name is required' }]} >
                                <Input placeholder="Enter Here" onChange={(e) => handleEmployeeChange('lastname', e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} >
                            <Form.Item label="Email" name='email' rules={[{ required: true, message: 'Email is required' }, {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },]}>
                                <Input
                                    onChange={(e) => handleEmployeeChange('email', e)}
                                    placeholder="Enter Here"
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} >
                            <Form.Item label="Contact Number" name='contactnumber' rules={[{ required: true, message: 'Contact Number is required' }, {
                                validator: validateContactNumber,
                            },]}>
                                <Input
                                    placeholder="Enter Here"
                                    type='number'
                                />
                            </Form.Item>
                        </Col>
                        <Col sm={12} >
                            <Form.Item label="Password" name='password' rules={[{ required: true, message: 'Password is required' }, {
                                validator: validateStrongPassword,
                            },]}>
                                <Input
                                    placeholder="Enter Here"
                                    onChange={(e) => handleEmployeeChange('password', e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="bd_drawerFoot">
                        <Button className='BG_ghostButton' onClick={handleReset}>Reset</Button>
                        <button className='BG_mainButton' >Add</button>
                    </div>
                </Form>
            </Drawer>

                   </>
    )
}
export default EmployeeList
