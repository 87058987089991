import { APIService } from "../../APIService";
import { baseUrl, bidBaseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'
import axios from "axios";
const Bidlogin = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/user-login`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

export const BidAuthServices = {
    Bidlogin,

}