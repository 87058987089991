// @ts-nocheck
import React from 'react';
import { Page, Document, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import InvoiceItemsTable from './InvoiceItemsTable'


const InvoicePdf = ({ invoice, dataSource, optionVal, columnLabels }) => {
    const updatedProjects = dataSource?.map(project => {
        const updatedProject = {};
        for (const key in project) {
            if (columnLabels?.hasOwnProperty(key)) {
                updatedProject[key] = project[key];
            }
        }
        // console.log(updatedProject, "updatedProject")
        return updatedProject;
    });

    // console.log(updatedProjects, "updatedProjects")


    return (
        <>
            {
                optionVal !== '' ?
                    <Document>
                        <Page size="A2" style={styles.page2} wrap>
                            <View style={styles.container}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableCell}>
                                            Sr. No.
                                        </Text>
                                        {Object?.values(columnLabels)?.map((header, index) => (
                                            <Text key={index} style={styles.tableCell}>
                                                {header?.name}
                                            </Text>
                                        ))}
                                    </View>


                                    {updatedProjects?.map((item, rowIndex) => (
                                        <View style={styles.tableRow} key={rowIndex}>
                                            <Text style={styles.tableCell}>{rowIndex + 1}</Text>
                                            {Object.values(item)?.map((cell, cellIndex) => (
                                                <Text key={cellIndex} style={styles.tableCell}>
                                                    {cell}
                                                </Text>
                                            ))}
                                        </View>
                                    ))}
                                </View>
                            </View>
                        </Page>
                    </Document>

                    :
                    <Document >
                        <Page size="A4" style={styles.page}>
                            <div className="invoice_wrapper">
                                <View>
                                    <View style={styles.imgrow}>
                                        <Image style={styles.logo} src={invoice.logo} />
                                    </View>
                                    <View style={{ marginVertical: -60, paddingBottom: 30 }}>
                                        <View style={styles.titlerow}>
                                            <Text style={styles.title}>Growth Grids Pvt. Ltd.</Text>
                                        </View>
                                        <View style={[styles.invoiceNoContainer, { alignSelf: 'flex-end', justifyContent: 'flex-end' }]}>
                                            <Text style={styles.invoiceDate}>N 79-80 Adinath Nagar, JLN Marg </Text>
                                            <Text style={styles.invoiceDate}>Jaipur-302018, Raj, India</Text>
                                            <Text style={[styles.invoiceDate, { paddingTop: 7 }]}>+91-9773356001, +91-9773356002</Text>
                                            <Text style={styles.invoiceDate}>CIN: U72900RJ2022PTC084739</Text>

                                        </View>
                                    </View>


                                    <View style={styles.invoiceheading}>
                                        <Text style={styles.invo}>Invoice</Text>
                                    </View>
                                </View>

                                <View style={[styles.company_inner_data, { width: '100%', borderWidth: 1, padding: 10, marginBottom: 3, alignSelf: 'center' }]}>

                                    <View style={[styles.company_inner_datas, { width: '45%' },]}>

                                        <View>
                                            <Text style={[styles.invoiceDate, { marginBottom: 5 }]}>N 79-80 Adinath Nagar, JLN Marg</Text>
                                            <Text style={[styles.invoiceDate, { marginBottom: 5 }]}>Jaipur-302018</Text>

                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={styles.bill}>GSTIN/UIN :- </Text>
                                            <Text style={styles.invoiceDate}>08AAKCG1519K1ZF</Text>
                                        </View>


                                        <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                            <Text style={styles.label}>Email :- </Text>
                                            <Text style={styles.invoiceDate}>business@growthgrids.com</Text>
                                        </View>

                                        {/* <View style={styles.invoiceNoContainer}>
                            <Text style={styles.bill}>Phone No. :- </Text>
                            <Text style={styles.invoiceDate}>+91-9773356001, +91-9773356002</Text>
                        </View> */}
                                        <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                            <Text style={styles.bill}>State Name :- </Text>
                                            <Text style={styles.invoiceDate}>Rajasthan, Code: 08 </Text>
                                        </View>
                                    </View>

                                    <View style={[styles.company_inner_data, { width: '35%' }]}>

                                        <View style={styles.invoiceNoContainer}>
                                            <Text style={styles.bill}>Invoice No :-</Text>
                                            <Text style={styles.invoiceDate}>{invoice?.invoice_no}</Text>
                                        </View >
                                        {/* <InvoiceNo invoice={invoice} /> */}
                                    </View>

                                    <View style={[styles.company_inner_data, { width: '20%' }]}>
                                        <View>
                                            <Text style={styles.bill}>Date :- </Text>
                                            <Text style={styles.invoiceDate}>{invoice.trans_date}</Text>

                                        </View>
                                        {/* <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>{invoice?.company ? "Company Name:- " : "Client Name:- "}<br /> </Text>
                            <Text style={styles.invoiceDate}>{invoice?.company ? `${invoice?.company}` : `${invoice?.userFullName}`}</Text>
                        </View>
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>Address :-</Text>
                            <Text style={styles.invoiceDate}> {invoice?.address ? `${invoice?.address}` : `${invoice?.address_form}`},{invoice?.city_name}</Text>
                        </View>
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>State Name :-</Text>
                            <Text style={styles.invoiceDate}> {invoice?.state},{invoice?.country_name}</Text>
                        </View>
                        {invoice.gst && invoice.gst_number && (
                            <View style={styles.invoiceNoContainer}>
                                <Text style={styles.label}>GST No. :-</Text>
                                <Text style={styles.invoiceDate}>{invoice.gst ? `${invoice.gst}` : `${invoice.gst_number}`}</Text>
                            </View>
                        )}
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>Contact No. :- </Text>
                            <Text style={styles.invoiceDate}>{invoice?.phone}</Text>
                        </View>
                        <View style={styles.invoiceNoContainer}>
                            <Text style={styles.label}>Email :- </Text>
                            <Text style={styles.invoiceDate}>{invoice?.email}</Text>

                        </View> */}
                                    </View>

                                </View>
                                <View style={{ borderWidth: 1, padding: 10 }}>
                                    <View style={styles.invoiceNoContainer}>
                                        <Text style={styles.bill}>Buyer (Bill to)</Text>
                                        {/* <Text style={styles.label}>{invoice?.company ? "Company Name:- " : "Client Name:- "}<br /> </Text> */}
                                        <Text style={styles.invoiceDate}>{invoice?.company ? `${invoice?.company}` : `${invoice?.userFullName}`}</Text>
                                    </View>
                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        <Text style={styles.label}>Address :-</Text>
                                        <Text style={styles.invoiceDate}> {invoice?.address ? `${invoice?.address}` : `${invoice?.address_form}`},{invoice?.city_name}</Text>
                                    </View>

                                    {invoice.gst && invoice.gst_number && (
                                        <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                            <Text style={styles.label}>GST No. :-</Text>
                                            <Text style={styles.invoiceDate}>{invoice.gst ? `${invoice.gst}` : `${invoice.gst_number}`}</Text>
                                        </View>
                                    )}
                                    {
                                        invoice?.pan_number !== null ?
                                            <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                                <Text style={styles.label}>Pan No. :-</Text>
                                                <Text style={styles.invoiceDate}>{invoice?.pan_number}</Text>
                                            </View>
                                            :
                                            <>
                                            </>
                                    }

                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        <Text style={styles.label}>Membership :- </Text>
                                        <Text style={styles.invoiceDate}>{invoice.membership_no}</Text>
                                    </View>
                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        {/* <Text style={styles.label}>State Name :-</Text> */}
                                        <Text style={styles.label}>State Name :-</Text>
                                        <Text style={styles.invoiceDate}> {invoice?.state},{invoice?.country_name}</Text>
                                    </View>
                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        <Text style={styles.label}>Contact No. :- </Text>
                                        <Text style={styles.invoiceDate}>{invoice?.phone}</Text>
                                    </View>
                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        <Text style={styles.label}>Email :- </Text>
                                        <Text style={styles.invoiceDate}>{invoice?.email}</Text>
                                    </View>
                                    <View style={[styles.invoiceNoContainer, { flexDirection: 'row' }]}>
                                        <Text style={styles.label}>Place of Supply :- </Text>
                                        <Text style={styles.invoiceDate}>{invoice?.Place_of_supply}</Text>
                                    </View>

                                </View>
                                <InvoiceItemsTable invoice={invoice} />

                            </div>
                        </Page>
                    </Document >
            }
        </>

    )
};

export default InvoicePdf;



const styles = StyleSheet.create({
    page: {
        paddingTop: 15,
        paddingLeft: 20,
        paddingRight: 20,
    },
    title: {
        fontSize: 16,
        textAlign: "center",
        marginLeft: 10,
        borderBottom: 1,
        paddingBottom: 10,
        // marginTop: 20
    },
    invo: {
        fontSize: 18,
        textAlign: "center",
    },
    row: {
        width: '100%',
        flexDirection: 'row',
        paddingTop: 20,
        gap: 40
    },
    logo: {
        width: 60,
        marginTop: 20
    },
    imgrow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
    },
    titlerow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        paddingBottom: 10,
        paddingTop: 5,

    },
    invoiceheading: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'center',
        marginVertical: 10,
        paddingTop: 10
    },
    logoText: {
        width: 200,
    },
    bill: {
        fontWeight: 'bold',
        marginBottom: 10,
        fontSize: 10
    },
    label: {
        color: "black",
        fontSize: 10,
        paddingRight: 2,
        marginBottom: 3
    },
    invoiceDate: {
        fontSize: 10,
        textAlign: 'left'
    },
    invoiceNoContainer: {
        paddingBottom: 5,
        marginBottom: 2
    },
    company_inner_data: {
        marginHorizontal: 10,
        flexDirection: "row"
    },
    table: {
        display: 'table',
        // width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        // margin: 'auto',
        flexDirection: 'row',
    },
    tableCell: {
        margin: 'auto',
        fontSize: 10,
        padding: 5,
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        flex: 1,
        flexGrow: 1
    },
    page2: {
        flexDirection: 'column',
        padding: 10,
    },
    container: {
        width: '100%',
        overflowX: 'auto',
        overflowY: 'auto',
        marginBottom: 10,
    },
    company_inner_datas: {

    }
});

{/* <Document>
    <Page size="A2" style={styles.page2} wrap>
        <View style={styles.container}>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>
                        Sr. No.
                    </Text>
                    {Object?.values(columnLabels)?.map((header, index) => (
                        <Text key={index} style={styles.tableCell}>
                            {header?.name}
                        </Text>
                    ))}
                </View>


                {updatedProjects?.map((item, rowIndex) => (
                    <View style={styles.tableRow} key={rowIndex}>
                        <Text style={styles.tableCell}>{rowIndex + 1}</Text>
                        {Object.values(item)?.map((cell, cellIndex) => (
                            <Text key={cellIndex} style={styles.tableCell}>
                                {cell}
                            </Text>
                        ))}
                    </View>
                ))}
            </View>
        </View>
    </Page>
</Document> */}

