// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Switch, Tabs, Collapse, Result } from 'antd';
import { CrownOutlined, SettingOutlined } from '@ant-design/icons';
import { RoleList } from 'Services/bidgrid/master/role/bidRole';
import { settingApi } from 'Services/bidgrid/masterSetting/settingApi';
import SettingManageModel from 'BidGrid/components/Models/SettingManageModel';
import UpdateProfile from '../EmployeeProfile/UpdateProfile';
import { Down, Right, ViewGridDetail, Info, FileExcel, Bookshelf, WalletThree } from '@icon-park/react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Padding } from '@mui/icons-material';
// import { REMARKS_STRINGS } from 'Constants/Strings';
const REMARKS_STRINGS = "table daata"
const { TabPane } = Tabs;
const { Panel } = Collapse;
const initDisable = {
 is_view: null,
 is_add: null,
 is_update: null,
 is_delete: null
}
const RolePermissions = () => {
 const [AddManageSetting, setAddManageSetting] = useState(false);
 const [getRoleId, setGetRoleId] = useState(null)
 const [roleList, setRoleList] = useState([])
 const [menuData, setMenuData] = useState([])
 const { bidgridData } = useSelector((state) => state.loginData)
 const { userBidInfo } = useSelector((state) => state?.userDetails)
//  const { bidMenu } = useSelector((state) => state?.bidMenuVal)
 const [disable, setDisable] = useState(initDisable)
 const [bidMenu, setBidMenu] = useState()
 useEffect(() => {
  const findActive = bidMenu?.find(val => val.menu_name === 'Setting')
  const activeChild = findActive?.children?.find(val => val.action_url?.includes('role'))
  setDisable({
   ...disable,
   is_view: activeChild?.is_view === '0' ? false : true,
   is_add: activeChild?.is_add === '0' ? false : true,
   is_update: activeChild?.is_update === '0' ? false : true,
   is_delete: activeChild?.is_delete === '0' ? false : true
  })
 }, [bidMenu])
 const handleSettingManage = () => {
  setAddManageSetting(true);
 }

 const templateManageClose = () => {
  setAddManageSetting(false);
 };

 const getbidRoleTender = async () => {
  try {
   const response = await RoleList.getbidRole()
   if (response?.data?.status == 1) {
    setRoleList(response?.data?.data)
    setGetRoleId(response?.data?.data[0]?.id)
   }
   else {
    setRoleList([])
   }
  } catch (error) {
   console.log(error)
  }
 };
 // menu list
 const fetchMenuList = async () => {
  const formData = new URLSearchParams();
  formData.append('role_id', getRoleId !== null ? getRoleId : roleList[0]?.id)
  formData.append('role_id', '2')
  try {
   const response = await RoleList.menuList(formData)
   if (response?.data?.status == 1) {
    let dataVal = response?.data?.data;
    const newData = dataVal?.filter(val => val?.parent_menu_id === 0)?.map(item => {
     const children = dataVal?.filter(child => child?.parent_menu_id === item?.id);
     return {
      ...item,
      children: children?.length > 0 ? children : []
     };
    });
    setMenuData(newData);
   }
   else {
    setMenuData([])
   }
  } catch (error) {
   console.log(error)
  }

 }

 // update menu list
 const updateMenuData = async (id, name, callList) => {
  const formData = new URLSearchParams();
  formData.append('role_id', getRoleId)
  formData.append('menu_id', id)
  formData.append('event_name', name)
  try {
   const response = await RoleList.setMenus(formData)
   if (response?.data?.status === '1') {
    if (callList) {
     fetchMenuList()
    }
   }
  } catch (error) {
   console.log(error)
  }
 }

 const handleChange = async (id, name, checked, data) => {
  if ((name === 'is_view' && data?.is_view === '0')) {
   data?.is_add === '0' && await updateMenuData(id, 'is_add', false)
   data?.is_delete === '0' && await updateMenuData(id, 'is_delete', false)
   data?.is_update === '0' && await updateMenuData(id, 'is_update', false)
   await updateMenuData(id, name, true)
  } 
  else if (
   (
    (name === 'is_add' && data?.is_add !== '0') ||
    (name === 'is_delete' && data?.is_delete !== '0') ||
    (name === 'is_update' && data?.is_update !== '0')
   ) &&
   data?.is_view !== '0'
  ) {
   await updateMenuData(id, 'is_view', false)
   await updateMenuData(id, name, true)
  } else {
   await updateMenuData(id, name, true)
  }
//   if (isConnected) {
//    console.log({ user_id: bidgridData?.data?.id, comp_id: userBidInfo?.comp_id, remarks: REMARKS_STRINGS.USER_PROFILE_UPDATE })
//    socket?.emit('user_update', { user_id: bidgridData?.data?.id, comp_id: userBidInfo?.comp_id, remarks: REMARKS_STRINGS.USER_PROFILE_UPDATE })
//   }
 }

 const handleRoleTab = (id) => {
  setGetRoleId(id)
 }

 const [panelStates, setPanelStates] = useState({
  Dashboard: true,
  Add: true,
  Tenders: true,
  Personal: true,
  Briefcase: true,
  'Employee List': true,
  'Meeting Schedule': true,
  Request: true,
  'Document Share': true,
  Setting: true,
 });

 const togglePanel = (panelKey) => {
  setPanelStates((prevState) => ({

   Dashboard: false,
   Add: false,
   Tenders: false,
   Personal: false,
   Briefcase: false,
   'Employee List': false,
   'Meeting Schedule': false,
   Request: false,
   'Document Share': false,
   Setting: false,

   [panelKey]: !prevState[panelKey],
  }));
 };


 useEffect(() => {
  getbidRoleTender()
 }, [])

 useEffect(() => {
  if (getRoleId !== null) {
   fetchMenuList()
  }


 }, [getRoleId])



 return (
  <>
   <div>
    <UpdateProfile/>
  </div>
  <div>
   <div className="roleManagement_wrapper">
    <div className="heading_flex">
     <h3 className="head_title" >Briefcash Permission</h3>
    </div>
    <div className='main_sec'>
      
    </div>
   </div>
  </div>
  </>
 )
}

export default RolePermissions;


