// @ts-nocheck
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import './assets/css/app.scss';
import Dashboard from "./pages/BDDashboard/Dashboard";
import Briefcase from "./pages/Briefcase";
import { useNavigate } from 'react-router-dom';
import Error404 from 'common/pages/Error404/Error404';
import Profile from "./pages/Profile/Profile";
import RolesPermission from './pages/rolePermission/RolesPermission';
import UpdateProfile from './pages/EmployeeProfile/UpdateProfile';
import BidGridMaster from 'Includes/BidGridMaster';
import BdLogin from './pages/login/Login';
import '../assests/style.css';
import '../assests/styleResponsive.css';
import { ProtectedRoutesBid } from 'utils/ProtectedRoutesBid';
import BIDGRIDROUTES from './bidRoute/bidRoutes';
import EmployeeList from './pages/EmployeeList';
import { useSelector } from 'react-redux';
import ROUTES from 'Constants/Routes';
const BdHeader = lazy(() => import('../BidGrid/components/Includes/BdHeader'));
const BdFooter = lazy(() => import('../BidGrid/components/Includes/BdFooter'));

const BidGridApp = () => {
 // const { bidgridData } = useSelector((state) => state.loginData)
 const { userBidInfo } = useSelector((state) => state.userDetails)
 const navigate = useNavigate()
 const checkAuth = () => {
  let authForLogin = localStorage.getItem('bidToken');
  if (authForLogin) {
   const currentPath = window.location.pathname;
   if (currentPath === ROUTES?.BD_LOGIN) {
    navigate(ROUTES?.DASHBOARD, { replace: true })
    // window.location.reload()
    window.open(ROUTES?.DASHBOARD, "_blank")
   }

  }
 };

 useEffect(() => {
  checkAuth()
 },[])

 return (
  <>
   <BidGridMaster />
   <BdHeader />
   <Routes>
    <Route path={BIDGRIDROUTES?.BD_LOGIN} element={<BdLogin />} />
    <Route path={BIDGRIDROUTES?.DASHBOARD} element={<ProtectedRoutesBid><Dashboard /></ProtectedRoutesBid>} />
    <Route path={BIDGRIDROUTES?.EMPLOYEELIST} element={<ProtectedRoutesBid><EmployeeList /></ProtectedRoutesBid>} />
    <Route path={BIDGRIDROUTES?.briefcase} element={<ProtectedRoutesBid><Briefcase /></ProtectedRoutesBid>} />
    <Route path={BIDGRIDROUTES?.PROFILE} element={<ProtectedRoutesBid><Profile /></ProtectedRoutesBid>} />
    <Route path={BIDGRIDROUTES?.EMP_PROFILE} element={<ProtectedRoutesBid><UpdateProfile /></ProtectedRoutesBid>} />
    {/* <Route path={BIDGRIDROUTES?.ROLEPERMISSIONS} element={<ProtectedRoutesBid><RolesPermission /></ProtectedRoutesBid>} /> */}
    <Route path='*' element={<Error404 />} />
   </Routes>
   <Suspense fallback={<div></div>}>
    <BdFooter />
   </Suspense>
  </>
 )
}
export default BidGridApp;


