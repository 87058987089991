// @ts-nocheck
import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { convertToWords } from './InvoiceTableFooter';

const styles = StyleSheet.create({
    sign: {
        fontSize: 11,
        top: 40
    },
    fonthead: {
        fontSize: 11
    },
    signfoot: {
        fontSize: 11,
        marginTop: 50,
        marginLeft: "auto",
        borderTop: 1,
        borderLeft: 1,
        padding: 40
    },
    comp: {
        fontSize: 12,
        fontWeight: 'bold',
        bottom: 40

    },
    amount: {
        fontSize: 12
    }


});

const Signature = ({ item }) => {
    return (
        <>

            <View style={{ borderWidth: 1, marginVertical: 20, width: "100%" }}>
                <View style={{ padding: 10, gap: 5 }}>
                    <Text style={styles.fonthead}>Tax Amount (in words):-</Text>

                    <Text style={styles.amount}>{item?.map(items => convertToWords(parseFloat(items?.amount) * 0.18))} Only</Text>
                    <View>
                        <Text style={styles.fonthead}>Bank Details:-</Text>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.amount}>Bank Name:-</Text>
                            <Text style={styles.amount}>ICICI Bank</Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.amount}>Bank Account No:-</Text>
                            <Text style={styles.amount}>677505602338</Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.amount}>IFSC Code:-</Text>
                            <Text style={styles.amount}>ICIC0006775</Text>
                        </View>

                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.amount}>Branch:-</Text>
                            <Text style={styles.amount}> Prakrit bharti Academy Grurunanak Road,Malviya nagar,Jaipur,302018</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.signfoot}>
                    <Text style={styles.comp}>
                        For Growth Grids
                    </Text>
                    <Text style={styles.sign}>(Authorised Signature)</Text>
                </View>
            </View>
            <View style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "12" }}>
                <Text style={{ textAlign: "center", marginLeft: "37%" }}>
                    This is a Computer Generated Invoice
                </Text>
            </View>
        </>

    )
}

export default Signature;