// @ts-nocheck
import axios from "axios";
import { bidBaseUrl } from "utils/configurable";
import 'react-toastify/dist/ReactToastify.css';

const getUserList = async () => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/users-list-all`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const addEmployee = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/user-add`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const updateEmployee = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.put(`${bidBaseUrl}/user-details-update`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


export const bidEmployeeList = {
    getUserList,
    addEmployee,
    updateEmployee
}