export let isAuth = sessionStorage.getItem('auth')

const currentProtocol = window.location.href;
export let docAuth = 'https://api.growthgrids.com/bd_growthgrids/doc_file/';
export let ClientId = '';
export let baseUrl = '';
export let baseUrlSitemap = "";
// export let bidBaseUrl = 'https://web.growthgrids.com/api/user'
// export let bidBaseUrl = "http://localhost:8080/api/user"
export let bidBaseUrl = "https://doc.mystorepedia.com/api/user"
// export let chat_socket_url = 'https://demo.growthgrids.com'
// export let socketBaseUrl = 'https://demo.growthgrids.com'
// export let docurlchat = 'https://web.growthgrids.com/'
// export let statBaseUrl = 'http://202.131.122.246:3013/api/user'
export let profileImageAuth = '';


if (currentProtocol.includes('http://localhost')) {
    baseUrl = process.env.REACT_APP_DEV_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_DEV_API_BASE_URL_SITEMAP
    profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = '785835417311-1gqg8gm9eplguflcme7prbh2m25dkmdf.apps.googleusercontent.com'
} else if (currentProtocol.includes('https://development.growthgrids.com')) {
    baseUrl = process.env.REACT_APP_DEV_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_DEV_API_BASE_URL_SITEMAP;
    docAuth = 'https://testing.growthgrids.com/bd_growthgrids/doc_file/'
    profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com"
}
else if (currentProtocol.includes('https://staging.growthgrids.com')) {
    baseUrl = process.env.REACT_APP_STAG_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_STAG_API_BASE_URL_SITEMAP;
    docAuth = 'https://api.growthgrids.com/bd_growthgrids/doc_file/'
    profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com"
}
else if (currentProtocol.includes('https://apps.growthgrids.com')) {
    baseUrl = process.env.REACT_APP_PROD_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_PROD_API_BASE_URL_SITEMAP;
    docAuth = 'https://api.growthgrids.com/bd_growthgrids/doc_file/'
    profileImageAuth = `https://api.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com"
}
else if (currentProtocol.includes('http://apps.growthgrids.com')) {
    baseUrl = process.env.REACT_APP_PROD_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_PROD_API_BASE_URL_SITEMAP;
    docAuth = 'https://api.growthgrids.com/bd_growthgrids/doc_file/'
    profileImageAuth = `https://api.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com"
}
else {
    baseUrl = process.env.REACT_APP_DEV_API_BASE_URL;
    baseUrlSitemap = process.env.REACT_APP_DEV_API_BASE_URL_SITEMAP;
    docAuth = 'https://api.growthgrids.com/bd_growthgrids/doc_file/'
    profileImageAuth = `https://testing.growthgrids.com/bd_growthgrids/public/uploads/profile/`
    ClientId = "530982732606-dr2jnt7qqb2csqfs9e091jiaslmgcnic.apps.googleusercontent.com"
}








