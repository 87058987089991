// @ts-nocheck
import axios from "axios";
import { bidBaseUrl } from "utils/configurable";


const getAddTenderList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.post(`${bidBaseUrl}/tender-list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const updateAddTenderList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.put(`${bidBaseUrl}/tenderdetail-update`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const deleteAddTenderList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.delete(`${bidBaseUrl}/designation-delete`, { data: data, headers: headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderCountryList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/country-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderStateList = async (data) => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/state-list`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderCityList = async (data) => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/city-list`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderCycleList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/tenderscope-list`, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderRegionList = async (data) => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/region-list`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderCurrencyList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/currency-list`, { headers })
        console.log(response, 'response')
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderFundingList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/tenderfundingagency-list`, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderSectorList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/tendersector-list`, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const AddLiveTenderList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/newtender-add`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
const getTenderLocatedList = async () => {
    const headers = {
        // 'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/nationality-list`, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}
export const AddTenderList = {
    getAddTenderList,
    updateAddTenderList,
    deleteAddTenderList,
    getTenderCountryList,
    getTenderStateList,
    getTenderCityList,
    getTenderCycleList,
    getTenderCurrencyList,
    getTenderFundingList,
    getTenderSectorList,
    AddLiveTenderList,
    getTenderRegionList,
    getTenderLocatedList
}