import React, { useEffect, useState, useRef } from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { COMMON_STRINGS } from 'Constants/Strings';

import { Row, Col, Form, Select, DatePicker, Input, Tabs } from 'antd';
const PasswordChangeBid = (props) => {
    const { form, personalInputData, handleInputChange, handleReset, postPasswordChange, setPersonalInputData } = props
    const [errorMessage, setErrorMessage] = useState("");
    const [showError, setShowError] = useState(true)
    const [errorCnfMessage, setErrorCnfMessage] = useState("");
    const [showCnfError, setShowCnfError] = useState(true)
    const [disableBtn, setDisableBtn] = useState(false)
    const notify = (error) => toast.error(error);

    const handleSubmit = () => {

        if (!personalInputData?.old_password && !personalInputData?.password && !personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.EMPTY_ALL_FIELDS)
        }
        else if (personalInputData?.old_password && !personalInputData?.password && !personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.ENTER_PASSWORD_CONFIRMPASSWORD)
        }
        else if (personalInputData?.old_password && personalInputData?.password && !personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.ENTER_CONFIRMPASSWORD)
        }
        else if (personalInputData?.old_password && !personalInputData?.password && personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.ENTER_password)
        }
        else if (!personalInputData?.old_password && personalInputData?.password && personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.ENTER_OLD_PASSWORD)
        }
        else if (personalInputData?.old_password === personalInputData?.password) {
            return notify(COMMON_STRINGS.OLD_NEW_PSW_NOT_SAME)
        }
        else if (personalInputData?.password !== personalInputData?.confirmPassword) {
            return notify(COMMON_STRINGS.PASSWORD_MISMATCH)
        } else if (showCnfError) {
            return
        } else {
            postPasswordChange(handleResetChangePassword)
        }
    }


    const handleChange = (name, e) => {
        setPersonalInputData({ ...personalInputData, [name]: e.target.value.trimStart() })
    }

    const handleChangeNewPassword = (name, e) => {
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var charcter = /(?=.*[!@#$&*])/g;

        e.preventDefault()
        let password = e.target.value
        // setNewPassword(password)
        if (password.length === 0) {
            setErrorMessage("");

        }
        else if (!password.match(lowerCase)) {
            setErrorMessage(COMMON_STRINGS.REQUIRED_LOWER_LETTER_PASSWORD);
            setShowError(true)
        } else if (!password.match(upperCase)) {
            setErrorMessage(COMMON_STRINGS.REQUIRED_UPPER_LETTER_PASSWORD);
            setShowError(true)
        }
        else if (!password.match(numbers)) {
            setErrorMessage(COMMON_STRINGS.REQUIRED_NUMBER_PASSWORD);
            setShowError(true)
        }
        else if (!password.match(charcter)) {
            setErrorMessage(COMMON_STRINGS.REQUIRED_SPECIAL_PASSWORD);
            setShowError(true)
        }
        else if (password.length < 8 || password.length > 16) {
            setErrorMessage(COMMON_STRINGS.REQUIRED_LENGTH_PASSWORD);
            setShowError(true)
        }

        else {
            setErrorMessage(COMMON_STRINGS.REQUIRED_STRONG_PASSWORD);
            setShowError(false)
        }
        setPersonalInputData({ ...personalInputData, [name]: e.target.value.trimStart() })
    }
    const handleCnfrmPassword = (name, e) => {
        e.preventDefault();
        var lowerCase = /[a-z]/g;
        var upperCase = /[A-Z]/g;
        var numbers = /[0-9]/g;
        var charcter = /(?=.*[!@#$&*])/g;
        // console.log(e.target.value, "e.target")
        e.preventDefault()
        let confirm_password = e.target.value
        // setCnfPassword(confirm_password)
        if (confirm_password.length === 0) {
            setErrorCnfMessage("");

        }
        else if (!confirm_password.match(lowerCase)) {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_LOWER_LETTER_PASSWORD);
            setShowCnfError(true)
        } else if (!confirm_password.match(upperCase)) {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_UPPER_LETTER_PASSWORD);
            setShowCnfError(true)
        }
        else if (!confirm_password.match(numbers)) {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_NUMBER_PASSWORD);
            setShowCnfError(true)
        }
        else if (!confirm_password.match(charcter)) {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_SPECIAL_PASSWORD);
            setShowCnfError(true)
        }
        else if (confirm_password.length < 8 || confirm_password.length > 16) {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_LENGTH_PASSWORD);
            setShowCnfError(true)
        }
        else {
            setErrorCnfMessage(COMMON_STRINGS.REQUIRED_STRONG_PASSWORD);
            setShowCnfError(false)
        }
        setPersonalInputData({ ...personalInputData, [name]: e.target.value.trimStart() })
    }

    function handleKeyDown(event) {
        if (event.key === 'Enter') {

            if (!disableBtn) {
                // handleCreateNewPass();
                event.preventDefault();
            }
        }
    }

    function handleResetChangePassword() {
        handleReset()
        setShowCnfError(true)
        setErrorCnfMessage('')
        setShowError(true)
        setErrorMessage('')
    }
    return (
        <div>
            <div className='profile_detail_info_box'>
                <div className='profile_sub_info'>
                    <Form form={form} name="control-hooks" autoComplete="off" className='bd_model_form'
                        // form={form}
                        layout="vertical"
                    >
                        <Row gutter={20}>

                            <Col sm={8} >
                                <Form.Item label="Old Password" name="Old password">
                                    <Input.Password

                                        onKeyDown={handleKeyDown}
                                        placeholder="Old Password"
                                        value={personalInputData?.old_password}
                                        onChange={(e) => handleChange('old_password', e)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col sm={8} >
                                <Form.Item label="New Password" name="New password" >
                                    <Input.Password

                                        onKeyDown={handleKeyDown}
                                        placeholder="New Password"
                                        value={personalInputData?.password}
                                        onChange={(e) => handleChangeNewPassword('password', e)}
                                    />
                                    <div className='divColor mt-2' style={{ color: `${!showError ? "green" : 'red'}` }}> {errorMessage} </div>
                                </Form.Item>
                            </Col>
                            <Col sm={8} >
                                <Form.Item label="Confirm Password" name="Confirm Password" >
                                    <Input.Password

                                        onKeyDown={handleKeyDown}
                                        placeholder="Confirm Password"
                                        value={personalInputData?.confirmPassword}
                                        onChange={(e) => handleCnfrmPassword('confirmPassword', e)}
                                    />
                                    <div className='divColor mt-2' style={{ color: `${!showCnfError ? "green" : 'red'}` }}> {errorCnfMessage} </div>
                                </Form.Item>
                            </Col>
                        </Row >
                    </Form >
                </div >
                <div className='profile_button_sec'>
                    <button className='BG_ghostButton' onClick={handleResetChangePassword}>Reset</button>
                    <button className='BG_mainButton' onClick={handleSubmit}>Save & Change</button>
                </div>
            </div >
        </div>
    )
}

export default PasswordChangeBid
