// @ts-nocheck
import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import RupeeImg from "../../../assests/img/rupee.png"

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        alignItems: 'center',
        width: '100%',

    },
    description: {
        width: "60%",
        padding: 12,
        fontSize: 10,
    },
    amount: {
        width: "100%",
        padding: 12,
        fontSize: 10,
    },
    amounts: {
        width: "100%",

        fontSize: 10,
    },
    subhead: {

        fontSize: 10,

    },
    gstamount: {
        width: "100%",
        fontSize: 10,
        textAlign: 'left'

    },
    col: {
        flexDirection: 'column',
        width: '20%'
    },
    logos: {
        width: 10,
        height: 10
    }
});

const Invoicehsn = ({ items }) => (
    <View style={{ borderWidth: 1 }}>
        <View style={[styles.container, { borderBottom: 1 }]}>

            <View style={{ flexDirection: 'column', width: '20%', height: 60, borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>HSN/SAC</Text>
            </View>


            <View style={{ flexDirection: 'column', width: '26%', height: 60, borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>Taxable Value</Text>
            </View>

            <View style={{ flexDirection: 'column', width: '26%', height: 60, borderRightWidth: 1 }}>
                <Text style={styles.amount}>Total GST</Text>
                <View style={{ flexDirection: 'row', borderTop: 1, }}>
                    <Text style={[styles.subhead, { borderRightWidth: 1, width: '30%', padding: 10 }]}>Rate</Text>
                    <Text style={[styles.subhead, { width: '70%', padding: 10 }]}>Amount</Text>
                </View>
            </View>


            <View style={{ flexDirection: 'column', width: '28%', height: 60 }}>
                <Text style={[styles.amount, { borderBottom: 1 }]}>Total</Text>
                <Text style={[styles.subhead, { padding: 10 }]}>Total Invoice Amount</Text>
            </View>
        </View>

        <View style={[styles.container, { borderBottom: 1 }]}>

            <View style={{ flexDirection: 'column', width: '20%', borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>997331</Text>
            </View>


            <View style={{ flexDirection: 'column', width: '26%', borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>{items?.map(item => (item?.amount))}</Text>
            </View>

            <View style={{ flexDirection: 'column', width: '26%', borderRightWidth: 1 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={[styles.subhead, { borderRightWidth: 1, width: '30%', padding: 12 }]}>18%</Text>
                    <Text style={[styles.subhead, { width: '70%', padding: 12 }]}>{(parseFloat(items[0].amount) * 0.18)?.toFixed(2)}</Text>
                </View>
            </View>


            <View style={{ flexDirection: 'row', width: '28%', justifyContent: "center", alignItems: "center", padding: 12, }}>
                <Image style={styles.logos} src={RupeeImg} />
                <Text style={[styles.amounts, {}]}> {(((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2))}</Text>
            </View>
        </View>

        <View style={styles.container}>

            <View style={{ flexDirection: 'column', width: '20%', borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>Total</Text>
            </View>


            <View style={{ flexDirection: 'column', width: '26%', borderRightWidth: 1 }}>
                <Text style={[styles.amount]}>{items?.map(item => (item?.amount))}</Text>
            </View>

            <View style={{ flexDirection: 'column', width: '26%', borderRightWidth: 1 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={[styles.subhead, { borderRightWidth: 1, width: '30%', padding: 12 }]}></Text>
                    <Text style={[styles.subhead, { width: '70%', padding: 12 }]}>{(parseFloat(items[0].amount) * 0.18)?.toFixed(2)}</Text>
                </View>
            </View>


            <View style={{ flexDirection: 'row', width: '28%', justifyContent: "center", alignItems: "center", padding: 12, }}>
                <Image style={styles.logos} src={RupeeImg} />
                <Text style={[styles.amounts, { padding: "0" }]}>{(((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2))}</Text>
            </View>
        </View>




    </View>
);

export default Invoicehsn