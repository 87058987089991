import axios from "axios";
import { bidBaseUrl } from "utils/configurable";



const getuserdetails = async (data) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': localStorage.getItem('bidToken')
    };

    try {
        const response = await axios.post(`${bidBaseUrl}/user-details-edit`, data, { headers });
        return response;
    } catch (error) {
        return error;
    }
};


export const ProfileDetails = {
    getuserdetails
}