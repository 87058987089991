// @ts-nocheck
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#ccc',
        alignItems: 'center',
        padding: 6,
        width: '100%',

    },
    description: {
        width: "60%",
        padding: 12,
        fontSize: 10,
    },
    amount: {
        width: "20%",
        padding: 12,
        fontSize: 10,
    },
});

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        {/* <Text style={styles.description}>Sr no.</Text> */}
        <Text style={styles.description}>Particulars</Text>
        <Text style={styles.amount}>HSN/SAC</Text>
        <Text style={styles.amount}>Amount (INR)</Text>
        {/* <Text style={styles.amount}>Tax (18%)</Text> */}
        {/* <Text style={styles.amount}>T.Amount</Text> */}
    </View>
);

export default InvoiceTableHeader