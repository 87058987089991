// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Space, Upload, Select } from 'antd';
import { Down, UploadOne } from '@icon-park/react';
import "../../assets/css/app.scss"
import { DesignationList } from 'Services/bidgrid/master/designation/bidDesignation';
import { toast } from 'react-toastify';

const { Search } = Input;
const { Option } = Select;



const Model = (props) => {

    const { columnLabels } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const [formFields, setFormFields] = useState([])
    const [form] = Form.useForm();
    const [selectedSector, setSelectedSector] = useState('')
    const [disableBtn, setDisableBtn] = useState(false)

    const notify = (error) => toast.error(error);

    // console.log(columnLabels, "labels")
    // const handleInputChange = (item, e) => {
    //     const inputValue = e.target.value;
    //     if (inputValue.startsWith(' ')) {
    //         return;
    //     } else if (/^\d+$/.test(inputValue)) {
    //         return;
    //     } else {
    //         let arr = formFields.map((val) => {
    //             if (val?.key === item?.key) {
    //                 return { ...val, initVal: e.target.value }
    //             }
    //             return val
    //         })
    //         setFormFields(arr)
    //     }


    const handleInputChange = (item, e) => {
        const inputValue = e.target.value; // Remove leading and trailing spaces
        const forbiddenChars = /[{}.\[\]""''`~;:\-_@#$%^&*()<>?/|+=!\\]/;

        if (inputValue === '' || forbiddenChars.test(inputValue)) {
            setErrorMessage(`${item?.name} is required`);
        } else {
            setErrorMessage('');
        }

        let arr = formFields.map((val) => {
            if (val?.key === item?.key) {
                return { ...val, initVal: inputValue }
            }
            return val;
        });
        setFormFields(arr);
    };
    useEffect(() => {
        const arr = Object.entries(props?.columnLabels).map(([key, value]) => {
            return { key, name: value?.name, required: value?.required, initVal: props?.modalData[key] };

        });
        // console.log(arr, 'arr')

        const convertedObject = arr?.reduce((acc, item) => {
            acc[item.name] = item.initVal;
            return acc;
        }, {});

        form.setFieldsValue(convertedObject);
        setFormFields(arr)
    }, [props?.open])
    const handleReset = () => {
        form.resetFields()
        setErrorMessage('');
        const newArray = formFields?.map((obj) => ({ ...obj, initVal: '' }));
        setFormFields(newArray)
    }

    const handleSubmit = () => {
        let convertedObject = { id: props?.modalData?.id }
        convertedObject = formFields?.reduce((acc, item) => {
            acc[item.key] = item.initVal;
            return acc;
        }, {});
        convertedObject['id'] = props?.modalData?.id
        if (props?.sectorOptions?.length > 0) {
            convertedObject['sector_id'] = selectedSector
        }
        let isError;
        for (const item of formFields) {
            if (Object.keys(convertedObject).some((val) => item?.key === val && item?.required && convertedObject[item?.key] === '')) {
                isError = true;
                setErrorMessage(`${item?.name} is required`);
                // notify(`${item?.name} is required!!`);
                break;
            } else {
                // props?.handleClose()
            }
        }
        if (!isError) {
            setDisableBtn(true)
            setErrorMessage('');
            props?.handleUpdate(convertedObject)
                .then(response => {
                    if (response?.data?.status == 1) {
                        props?.handleClose()

                    }
                })
                .catch(error => {
                    // Handle errors here
                    console.error(error);
                });

            setTimeout(() => {
                setDisableBtn(false)
            }, 2000);
        }



    }


    const handleSelectChange = (value) => {
        setSelectedSector(value);
        form?.setFieldValue({ sectorid: value })
    }

    const handleKeyPress = (e) => {
        const forbiddenChars = /[{}.\[\]""''`~;:\-_@#$%^&*()<>?/|+=!\\]/;
        if (forbiddenChars.test(e.key)) {
            e.preventDefault();
        } else if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        } else if (e.key === ' ' && e.target.selectionStart === 0) {
            e.preventDefault();
        }
    };

    const handleClose = () => {
        setErrorMessage('');
        props.handleClose();
        form.resetFields();
    };

    return (
        <div>
            <Modal title={` Edit ${props?.title}`} className='bd_model_main'
                open={props?.open}
                onOk={props?.handleClose}
                onCancel={handleClose}
                footer={[
                    <button key="back" onClick={handleReset} className='BG_ghostButton' >
                        Reset
                    </button>,
                    <button key="submit" onClick={handleSubmit} className='BG_mainButton' disabled={disableBtn}>
                        Submit
                    </button>

                ]}
            >
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off" onKeyDown={handleKeyPress}>
                    {formFields?.map((item, index) => {

                        return (
                            <Form.Item key={index} name={item?.name} label={`${item?.name}:`}>
                                <Input value={item?.initVal} onChange={(e) => handleInputChange(item, e)} placeholder='Enter here' />
                                {errorMessage && <span className="bd_errorMessage" style={{ color: "#ff4d4f" }} >{errorMessage}</span>}
                            </Form.Item>
                        )
                    })}
                    {props?.sectorOptions?.length > 0 && <Form.Item name="sectorid" label="Sub-Sector:"
                        rules={[{ required: true }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Option"
                            options={props?.sectorOptions?.map((item, index) => {
                                return {
                                    label: item?.sector_name,
                                    value: item?.id
                                };
                            })}
                            value={selectedSector?.sector_name}
                            name="sector_name"
                            onChange={(value) => handleSelectChange(value)}
                            suffixIcon={<Down theme="outline" size="18" fill="#747474" />}
                        ></Select>
                    </Form.Item>}
                </Form>

            </Modal>
        </div>
    )
}

export default Model;
