// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Down } from '@icon-park/react';
import { View, Text, Document, Page, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InvoicePdf from 'common/components/InvoicePdf/InvoicePdf';
import { pdf } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
const { Option } = Select;

const ExportDatatable = ({ dataSource, columnLabels }) => {

    // console.log(dataSource, "dataSource")
    // console.log(columnLabels, "columnLabels")
    const selectOptions = {
        pdf: 'PDF',
        csv: 'CSV',
        print: 'Print',
    };

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#fff',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        table: {
            width: '100%',
            borderWidth: 1,
            borderColor: '#000',
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCell: {
            flex: 1,
            padding: 5,
            borderColor: '#000',
        },
        tableHeader: {
            backgroundColor: '#f0f0f0',
            fontWeight: 'bold',
            padding: 5,
            borderColor: '#000',
        },
    });

    const location = useLocation()
    const pdfName = location?.pathname?.split('/')
    const getPdfName = pdfName[pdfName?.length - 1]

    // const downloadPDF = (data) => (
    //     <Document>
    //         <Page size="A4" style={styles.page}>
    //             <View style={styles.section}>
    //                 <View style={styles.table}>
    //                     <View style={styles.tableRow}>
    //                         {Object.keys(data[0])?.map((header, index) => (
    //                             <Text key={index} style={[styles.tableHeader, styles.tableCell]}>
    //                                 {header}
    //                             </Text>
    //                         ))}
    //                     </View>
    //                     {data.map((item, rowIndex) => (
    //                         <View style={styles.tableRow} key={rowIndex}>
    //                             {Object.values(item).map((cell, cellIndex) => (
    //                                 <Text key={cellIndex} style={styles.tableCell}>
    //                                     {cell}
    //                                 </Text>
    //                             ))}
    //                         </View>
    //                     ))}
    //                 </View>
    //             </View>
    //         </Page>
    //     </Document>
    // );

    const generatePdfDocument = async (datasource, format) => {
        if (datasource?.length > 0) {
            const blob = await pdf((
                <InvoicePdf
                    dataSource={datasource}
                    columnLabels={columnLabels}
                    optionVal={format}
                />

            )).toBlob();
            saveAs(blob, String(getPdfName.charAt(0).toUpperCase() + getPdfName.slice(1)))
        }

    };




    const generateCSVData = (data, columnLabels) => {
        const csvData = [];
        const headerRow = ['Sr. No.', ...Object.keys(columnLabels).map(key => columnLabels[key].name)];
        csvData.push(headerRow);
        data.forEach((item, index) => {
            const row = [index + 1, ...Object.keys(columnLabels).map(key => item[key])];
            csvData.push(row);
        });

        return csvData;
    };

    const handleSelectExportData = (format) => {
        let dataToPrint;

        const filteredData = dataSource;
        switch (format) {
            case 'pdf':
                generatePdfDocument(dataSource, format);
                break;
            case 'csv':
                const csvData = generateCSVData(dataSource, columnLabels);
                const csvContent = csvData.map(row => row.join(',')).join('\n');
                const csvBlob = new Blob([csvContent], { type: 'text/csv' });
                const csvUrl = URL.createObjectURL(csvBlob);
                window.open(csvUrl);
                break;
            case 'print':
                window.print();
                // printData(filteredData);
                break;

            default:
                break;
        }
    };

    // console.log(dataSource, "datasource")
    // const printData = (data) => {
    //     // Render the data in a printable format
    //     const printableContent = data.map(item => {
    //         // Iterate over the keys of each item and dynamically generate the content
    //         const itemContent = Object.keys(item).map(key => {
    //             return `<p>${key}: ${item[key]}</p>`;
    //         }).join('');

    //         return `
    //         <div>
    //             ${itemContent}
    //         </div>
    //     `;
    //     }).join('');

    //     // Open a new window and inject the printable content for printing
    //     const printWindow = window.open('', '_blank');
    //     printWindow.document.write(`
    //         <html>
    //         <head>
    //             <title>Print Data</title>
    //             <style>
    //                 /* Add any custom styles for printing here */
    //             </style>
    //         </head>
    //         <body>
    //             ${printableContent}
    //         </body>
    //         </html>
    //     `);
    //     printWindow.document.close(); // Close the document for printing
    //     printWindow.print(); // Trigger printing
    // };

    return (
        <>

            <Form.Item className="export">
                <Select placeholder="Export" style={{ width: 120 }} suffixIcon={<Down theme="outline" size="20" fill="#636363" />} onChange={(e) => handleSelectExportData(e)}>
                    {Object.entries(selectOptions)?.map(([value, label]) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>




            </Form.Item>

        </>
    )
}

export default ExportDatatable 
