// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UserInfoServices } from "../Services/common/user/userinfo";
import { userInfoAction } from '../Redux/actions/common/userInfoAction';
import { DropdownValuesServices } from '../Services/common/dropdown/dropdownValues'
import { useDispatch } from "react-redux";
import { dropdownValAction, dynamicMenuAction, seoValuesAction } from '../Redux/actions/common/DropdownAction';
import { NavBarMenu } from "Services/common/navbar/NavBarMenu";
import { useLocation, useNavigate } from "react-router-dom";
const initialState = {
    country: [],
    state: [],
}
const DropdownValues = () => {
    const [data, setData] = useState(initialState)
    const objDetiail = new FormData()
    const location = useLocation()
    const val = location?.pathname;
    const str = val.replace('/', '')
    const dispatch = useDispatch()
    const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const { seoValue } = useSelector((state) => state.seoValues)
    const { userData } = useSelector((state) => state.loginData)
    const { dropdownValues } = useSelector((state) => state.dropdownCalVal)
    const navigate = useNavigate()
    useEffect(() => {
        let url = location.pathname;
        if (url.endsWith('/')) {
            url = url.replace(/\/+/g, '');
            navigate(url)
        }
    }, [location]);
    const userServiceRes = () => {

        objDetiail.append('user_id', userData?.user_data?.loginid)
        try {
            UserInfoServices.requestUser(objDetiail)
                .then((response) => {
                    if (response?.data?.status === 1) {
                        dispatch(userInfoAction(response.data.data))
                    }
                    else if (response?.validation_error) {
                        localStorage.clear();
                        window.location.reload();
                        localStorage.setItem("auth", "");
                    }
                })
        }
        catch {
            console.log('error')
        }
    }
    const getNavBarMenuItem = () => {
        try {
            NavBarMenu.getNavBar().then((response) => {
                let data = Object?.values(response?.data?.data)
                dispatch(dynamicMenuAction(data))

            });
        } catch (error) {
            console.log(error);
        }
    };
    const getCountry = async () => {
        try {
            await DropdownValuesServices.getCountry().then(res => {
                setData(prevState => ({ ...prevState, country: res?.data?.countries?.filter(val => val?.country_name !== 'NA') }))
            })
        } catch (error) {
            console.log(error)
        }
    }
    const getStates = async () => {
        objDetiail.append('country_id', 1);
        try {
            const res_state = await DropdownValuesServices.getStateDataByCountryId(objDetiail);
           

          
        } catch (error) {
            console.log(error);
        }
    };
    const dispatchDropdownValues = () => {
        if (data?.sector?.length !== 0 && data?.country?.length !== 0 && data?.client?.length !== 0 && data?.state?.length !== 0 && data?.fin_year?.length !== 0 && data?.fundingAgency?.length !== 0) {
            dispatch(dropdownValAction(data))
        }
    }
    useEffect(() => {
        // Function to handle URL changes
        const handleUrlChange = () => {
            var currentUrl = window.location.href;
            if (currentUrl.includes("tendergrid")) {
                document.body.className = "tender-grid-module";
            } else if (currentUrl.includes("document")) {
                if (str === "document/chatboat") {
                    document.body.className = "bid-grid-module bd_chat_module";
                } else {
                    document.body.className = "bid-grid-module";
                }

            } else {
                document.body.className = "tender-grid-module";
            }
        };

        // Initial setup
        handleUrlChange();

    }, [location.pathname]);

    useEffect(() => {
        if (window.location.href.includes("bidgrid")) {
            return
        } else {
            if (dynamicMenu?.length > 0) {
                return
            } else {
                if (localStorage.getItem('auth') !== '') {
                    userServiceRes()
                }
                getStates()
                getCountry()
                getNavBarMenuItem()
            }
        }

    }, [userData, dynamicMenu])

    useEffect(() => {
        dispatchDropdownValues()
    }, [data])
    return;
}

export default DropdownValues



