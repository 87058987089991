// @ts-nocheck
import axios from "axios";
import { bidBaseUrl } from "utils/configurable";


const getProspectiveList = async (data) => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/prospective-list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const addProspectiveList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.post(`${bidBaseUrl}/prospective-add`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getClientList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/tenderclient-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getFundingAgencyList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.get(`${bidBaseUrl}/tenderfundingagency-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getLeadCompanyList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.get(`${bidBaseUrl}/company-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getSectorList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.get(`${bidBaseUrl}/tendersector-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getCountryList = async () => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.get(`${bidBaseUrl}/country-list`, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getStateList = async (data) => {
    const headers = {
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.post(`${bidBaseUrl}/state-list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const deleteprospectiveList = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }

    try {
        const response = await axios.delete(`${bidBaseUrl}/prospective-delete`, { data: data, headers: headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const updateProspectiveList = async (data) => {
    const headers = {
        'Content-Type': "multipart/form-data",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/prospective-update`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const deleteProspectiveDocument = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/prospective-docs-delete`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const downloadProspectiveDocument = async (data) => {
    const headers = {
        'Content-Type': "application/x-www-form-urlencoded",
        "authorization": localStorage.getItem('bidToken')
    }
    try {
        const response = await axios.post(`${bidBaseUrl}/prospective-docs-down`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getStateListForBdFilter = async (data) => {
    const headers = {
        "authorization": localStorage.getItem('bidToken'),
        'Content-Type': "application/x-www-form-urlencoded",

    }

    try {
        const response = await axios.post(`${bidBaseUrl}/state-list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


export const bidProspective = {
    getProspectiveList,
    getClientList,
    getFundingAgencyList,
    getLeadCompanyList,
    getSectorList,
    getCountryList,
    getStateList,
    addProspectiveList,
    deleteprospectiveList,
    updateProspectiveList,
    deleteProspectiveDocument,
    downloadProspectiveDocument,
    getStateListForBdFilter
}