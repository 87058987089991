// @ts-nocheck
import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        alignItems: 'center',
        padding: 6,
        width: '100%'
    },
    amount: {
        width: '20%',
        padding: 12,
        fontSize: 11,
        color: "#000",
    },
    description: {
        width: '65%',
        padding: 12,
        flexDirection: 'column',
        gap: 5
    },
});


const InvoiceTableRow = ({ items, transactionId }) => {
    console.log(transactionId, "jjjj")
    const rows = items.map(item =>
        <>
            <View style={styles.container} key={item?.sno?.toString()}>
                <View style={styles.description}>
                    <Text style={{ fontSize: 11, color: "#000" }}>Tender Subscription</Text>
                    <Text style={{ fontSize: 11, color: "#000" }}>Transaction No:- {transactionId}</Text>
                </View>
                <Text style={styles.amount}>997331</Text>
                <Text style={styles.amount}>{item.amount}</Text>
                {/* <Text style={styles.amount}>{((parseFloat(items[0].amount) * 0.09) + parseFloat(items[0].amount * 0.09)).toFixed(2)}</Text> */}
                {/* <Text style={styles.amount}>{((parseFloat(items[0].amount) * 0.18) + parseFloat(items[0].amount)).toFixed(2)}</Text> */}
            </View>
        </>

    )
    return (<Fragment>{rows}</Fragment>)
};

export default InvoiceTableRow