import React from 'react';
import { Button, Modal } from 'antd';
import "../../assets/css/app.scss"
import { useLocation } from 'react-router-dom';

const Delete = (props) => {
  const { handleDelete, modalData, modalData2, deleteModal, setDeleteModal, fileDelete, fileName } = props
  const deleteHandler = () => {
    if (props?.deleteModal) {
      setDeleteModal(false)
    } else if (fileName === 'folder') {
      handleDelete(modalData2?.key)
      props?.onClose()
    } else if (fileName === "image") {
      fileDelete(modalData2)
      props?.onClose()
    }
    else {
      handleDelete(modalData?.id)
      props?.onClose()
    }

  }


  return (

    <Modal
      className="bd_delete_model"
      // visible={deleteModal || props?.open}
      // onCancel={() => deleteModal || props?.onClose()}
      visible={props?.deleteModal == true ? props?.deleteModal : props?.open}
      onCancel={props?.onClose}
      getContainer={() => document.getElementById('zoom')}
      footer={[
        <button className='BG_ghostButton' key="cancel" onClick={props?.onClose}>
          Cancel
        </button>,
        <button className='BG_deleteButton' key="delete" onClick={deleteHandler}>
          Delete
        </button>
      ]}
    >
      <div className='bd_delete_container'>
        <span className='bd_delete_spn'>Are you sure you want to delete this {props?.title?.toLowerCase()}</span>
        <p>If you delete the {props.title?.toLowerCase()}, you can't recover it.</p>
      </div>
    </Modal>
  )
}

export default Delete;